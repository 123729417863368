window.selectDropdown = function () {

  /** Инициализация селекта на странице объявлений */
  $('.js-select2Sort').each((index, el) => {
    $(el).select2({
      templateSelection: formatState,
      templateResult: formatState,
      selectOnClose: true,
      theme: 'default form-select2',
      minimumResultsForSearch: 100000,
      placeholder: $(el).data('placeholder'),
      width: '100%',
      language: {
        noResults: function () {
          return $(el).data('noresult-title');
        }
      },
    });
  });

  /** Ввод текта в default селект */
  function formatState(state) {
    return `${state.text}`;
  };
}
selectDropdown()
