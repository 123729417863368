import 'slick-carousel';
import { mainSliderCreate, mainSliderInit, mainSlidersSlideByIndex, mainSlidersSlideByDir } from './mainSlider';

const isDesktop = $(window).width() > 1024;
const currentSliderIndex = 0

mainSliderCreate();

setTimeout(() => {
  mainSliderInit();
}, 2000);

const defaultOptions = {
    infinite: true,
    draggable: true,
    focusOnSelect: false,
    accessibility: false,
    mobileFirst: false,
    dots: true,
    customPaging: function(slider, i) {
      return `<button class="pagination-custom-button" data-index="${i}" type="button">
        <div class="pagination-timer-left"><div></div></div>
        <div class="pagination-timer-right"><div></div></div>
      </button>`;
    },
    arrows: true,
    autoplay: true,
    fade: true,
    pauseOnHover: false,
    autoplaySpeed: 6000,
    dotsClass: 'main-slide__dots',
    appendArrows: $('.main-slide__arrows-wrapper'),
    prevArrow: '<button id="main-prev" type="button" class="main-slide__arrow main-slide__arrow_prev"></button>',
    nextArrow: '<button id="main-next" type="button" class="main-slide__arrow main-slide__arrow_next"></button>',
  }

  if(isDesktop) {
    const typographyWidth = $('.main-slide__typography').width() - 10;
    $('#main-arrows').css({
      width: 'calc(100% - ' + typographyWidth + 'px)'
    })
  }else{
    $('#main-arrows').css({
      display: 'none'
    })
  }

  $(document).on('click', '.pagination-custom-button', (e) => {
    const index = $(e.currentTarget).data('index');

    $('#js-main-slider').addClass('slickPause');
    $('#js-main-slider').slick('slickPause');
    $('#js-main-slider').slick('setOption', 'autoplay', false);

    mainSlidersSlideByIndex(index);
    console.log('currentIndex', index)
  });

  setTimeout(()=>{
    $('.js-effect').addClass('is-showed');
  }, 500)

  $('#js-main-slider').slick(defaultOptions)

  $('#js-main-slider').on('swipe', function(event, slick, direction){
    if (direction === 'right') {
      console.log('right', direction)
      mainSlidersSlideByDir('prev');
    } else {
      console.log('left', direction)
      mainSlidersSlideByDir('next');
    }
  })

  $('#js-main-slider .main-slide__dots .slick-active').removeClass('slick-active');
  setTimeout(() => {
    $('#js-main-slider .main-slide__dots li').eq(0).addClass('slick-active');
    console.log('timeout')
  });

  $('.main-slide__title')
    .on('mouseenter', (e) => {
      const current = $(e.currentTarget);
      const slide = current.parents('.main-slide')

      slide.addClass('is-hover');
      $('.js-mainImagesSlider .swiper-slide-active').addClass('is-hover')
    })
    .on('mouseleave', () => {
      $('.main-slide').removeClass('is-hover');
      $('.js-mainImagesSlider .swiper-slide-active').removeClass('is-hover');
    })
