/** Адаптив слайдера детальной страницы объявлений*/
$(window).on('load resize', initFotoramaParams);

function initFotoramaParams () {
  let isDesktop = $(window).width() >= 1025;
  let isTablet = $(window).width() < 1025;
  if (isDesktop) {
    $('.js-fotorama').fotorama({
      thumbwidth: '72px',
      thumbheight: '70px',
    });
  }
  if (isTablet) {
    $('.js-fotorama').fotorama({
      thumbwidth: '50px',
      thumbheight: '50px',
    });
  }
};

initFotoramaParams();

/** Определение количества слайдеров и позиции текущего слайдера*/
$(window).on('load', () => {
  $('.js-fotorama').on('fotorama:show', function (e, fotorama) {
    $('#navigation').html(fotorama.activeIndex + 1 + "/" + fotorama.size);
  });
});

