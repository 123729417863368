import { disableBodyScroll, clearAllBodyScrollLocks  } from 'body-scroll-lock';

$(function() {
  let isOpenedMoreBlock = false;
  const targetScroll = document.querySelector('#more-popup')

  /** Обработчик нажатия кнопки фильтра: Показать ещё */
  $('.js-show-more').click(function(e){
    e.preventDefault();
    if(!isOpenedMoreBlock) {
      $('#menu-mobile').slideUp(300);
      $('#more-popup').show(300);
      disableBodyScroll(targetScroll)
      isOpenedMoreBlock = true;
    }else{
      $('#more-popup').hide(300);
      clearAllBodyScrollLocks();
      isOpenedMoreBlock = false
    }

  })

  /** Скрытие окна и разблокировка скролла */
  $('.popup-close').click(function(){
    $('#more-popup').hide(300);
    clearAllBodyScrollLocks();
    isOpenedMoreBlock = false
  })

  $('.js-show-more').click(function(e){
    const { localName } = e.currentTarget;

    /** Закрытие окна расширенного фильтра */
    if(localName === 'a') {
      $('#js-more-close').addClass('header__more-close_show');
      $('.js-action').hide();
      $('.header').addClass('header_shadow-bg');

      $('#js-more-close').on('click', function() {
        isOpenedMoreBlock = false;
        $(this).removeClass('header__more-close_show');
        $('.header').removeClass('header_shadow-bg');
        $('.js-action').show();
        $('#more-popup').hide(300);
        clearAllBodyScrollLocks();
      })
    }

  })

})
