/** Отображение табов и взаимод-е с ними*/
$(document).ready( function() {
	const classDefault = 'currency-popup_'
	let currentCurrency = '';
	const popup = $('#currency-popup');
	$('.js-tabs').on('click', 'li:not(.active)', function() {
		const _this = $(this);
		const currencyModificator = $(this).data('currency');

		currentCurrency = $('#currency-popup').data('current');
		popup.removeClass(classDefault + currentCurrency);
		popup.attr('data-current', currencyModificator)
		popup.data('current', currencyModificator)
		popup.addClass(classDefault + currencyModificator);

		const currentTab = _this.parents('.tabs').find('.tab').eq(_this.index());

		_this.addClass('active').siblings().removeClass('active');
		currentTab.siblings('.tab').hide();
		currentTab.fadeIn(350);
	})
});
