
/** Получаем список input */
let els__inputs = document.querySelectorAll('.confirm-input');
/** Максимальное количество символов */
let int__maxSimbols = 1;

/** Запускаем перебор всех input */
Array.prototype.slice.call(els__inputs).forEach(function (el__input, int__input) {
  el__input.addEventListener('input', function (e) {
    if (this.value.length >= int__maxSimbols) {
      let str__value = this.value,
        int__inputLength = els__inputs.length - int__input;

      str__value = str__value.substring(0, int__maxSimbols * int__inputLength);

      /** Разбиваем текст на несколько полей */
      for (let i = 0; i < els__inputs.length - int__input; i++) {
        let el__selectElement = els__inputs[int__input + i];

        /** Обрезаем от общей строки нужное количество символов */
        if (el__selectElement.value === '') {
          el__selectElement.value = str__value
            .substring(int__maxSimbols * i, int__maxSimbols * int__inputLength * (i + 1))
        };
        el__selectElement.value = el__selectElement.value.substring(0, int__maxSimbols);
      };

      /** Ищем следующий элемент, который нужно фокусировать */
      let int__idxNextElement = Math.ceil(str__value.length / int__maxSimbols) - 1 + int__input;
      els__inputs[int__idxNextElement].focus();
    };
  });
  /** Переход на предыдущее поле по нажатию Backspace */
  el__input.addEventListener('keydown', function (e) {
    if (e.keyCode === 8 && el__input.value.length === 0 && int__input !== 0) {
      els__inputs[int__input - 1].focus();
    };
  });
});

$(document).on('click', '.js-numberInputClear', (e) => {
  const $input = $(e.currentTarget).parent().find('.js-numberInput');
  $input.val('');
  $(e.currentTarget).removeClass('is_visible');
});
