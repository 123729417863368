import 'slick-carousel';

/** Инициализация слайдеров баннера */
const defaultOptions = {
  infinite: true,
  draggable: false,
  focusOnSelect: false,
  accessibility: false,
  mobileFirst: false,
  dots: false,
  arrows: false,
  autoplay: true,
  pauseOnHover: false,
  autoplaySpeed: 10000,
};

/** Возможность переличтывания слайдеров баннера */
$('.js-bannerSlider').each((index, element) => {
  $(element).slick(defaultOptions);
});

window.bannerSliderInit = function() {
  $('.js-bannerSlider').each((index, element) => {
    $(element).slick(defaultOptions);
  });
}
