/** Ввод адреса видео в Input и разблокировка кнопки загрузки*/
$(document).on('input change', '.js-videoLinkInput', (e) => {
  const currentTarget = $(e.currentTarget);
  const hiddenInput = currentTarget.parent().find('[name="video"]')
  const previewSrc = getYoutubePreview(currentTarget.val());

  if (previewSrc) {
    $('.js-videoLinkButton').prop('disabled', false);
    hiddenInput.val(currentTarget.val());
  } else {
    $('.js-videoLinkButton').prop('disabled', true);
  }
});

/** Добавление видео в загрузки при его наличии*/
$(document).on('click', '.js-videoLinkButton', (e) => {

  const $input = $('.js-videoLinkInput');
  const previewSrc = getYoutubePreview($input.val());
  const $previewVideo = $('.js-videoPreview');
  const $previewVideoImage = $('.js-videoPreviewImage');
  const $videoLink = $('.js-videoLink');

  e.preventDefault();

  if (previewSrc) {
    $previewVideoImage.css('background-image', `url("${previewSrc}")`);
    $previewVideo.show();
    $videoLink.hide();
    $previewVideo.removeClass('empty')
    $('.js-block-photo-video').addClass('is_active')
  }
});

/** Удаление видео из загрузки*/
$(document).on('click', '.js-videoPreviewDel', (e) => {
    const $previewVideo = $('.js-videoPreview');
    const $videoLink = $('.js-videoLink');
    const $input = $('.js-videoLinkInput');

    $input.val('');
    $previewVideo.hide();
    $videoLink.show();

    $previewVideo.addClass('empty')
    if ($('.file-upload__list').children('.file-upload__img').length === 0) {
      $('.js-block-photo-video').removeClass('is_active')
    }
    $('.js-videoLinkInput').val('')
    $('.js-videoLinkButton').prop('disabled', true)
  }
)

/** Реакция uploader на присутствие/отсутствие видео*/
$('.js-videoLinkInput').on('input', () => {
  const inputData = $('.js-videoLinkInput').val()
  if(inputData === '') {
    if ($('.file-upload__list').children('.file-upload__img').length === 0) {
      $('.js-block-photo-video').removeClass('is_active')
    }
  }
})

function getYoutubePreview(url) {
  const videoid = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

  if (videoid !== null && videoid[1].length === 11) {
    return `//img.youtube.com/vi/${videoid[1]}/0.jpg`;
  }
};
