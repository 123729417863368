const $searchForm = $('.js-searchForm');
const $searchFormInput = $('.js-searchFormInput');
const $searchFormClear = $('.js-searchFormClear');

/** Показ кнопки Найти */
$searchFormInput.on('input change', (e) => {
  const val = $(e.currentTarget).val();

  /** Если адресная строка не пустая */
  (val !== '')
    ? $searchForm.addClass('is_visible_buttons')
    : $searchForm.removeClass('is_visible_buttons');
});

/** Скрытие кнопки Найти при пустой адресной строке*/
$searchFormClear.on('click', () => {
  $searchFormInput.val('');
  $searchForm.removeClass('is_visible_buttons');

  if ($('.js-draw-pointer').hasClass('draw-disabled')) {
    $('.js-draw-pointer.draw-disabled').trigger('click');
  }
});

const $headerSearch = $('.top-search__input');
const $headerFormInput = $('.js-find');
const $headerFormSubmit = $('.top-search__submit');
const $headerFormClear = $('.top-search__close');

/** Отображение поисковой кнопки при изм-и знач-я строки ввода в header */
$headerFormInput.on('input change', (e) => {
  const val = $(e.currentTarget).val();

  /** Если адресная строка не пустая */
  (val !== '')
    ? $headerSearch.addClass('is_visible_buttons')
    : $headerSearch.removeClass('is_visible_buttons');
});

$headerFormClear.on('click', () => {
  $headerFormInput.val('');
  $headerSearch.removeClass('is_visible_buttons');
});

$('.js-locationAddressInput').on('input change', (e) => {
  const val = $(e.currentTarget).val();

  (val !== '')
    ? $('.search-form__clear-address').addClass('is_visible_clear')
    : $('.search-form__clear-address').removeClass('is_visible_clear');
});

/** Очистка адресной строки и скрытие поисковой кнопки при нажатии кнопки закрытия */
$('.search-form__clear-address').on('click', () => {
  $('.js-locationAddressInput').val('').trigger('input');
  $('#filter-form').trigger('change');
  $('.search-form__clear-address').removeClass('is_visible_clear');
  $('.dropdown-map-list').removeClass('dropdown-map-list__visible')
});
