$(function () {
  let isDesktop = $(window).width() > 1024;

  /** Скрытие dropdown menu при нажатии на область вокруг */
  $(document).on('click', (e) => {
    $('.submenu-desk-list').addClass('submenu-desk-list_hide');
    if (!isDesktop) {
      $('.user-dropdown-list').addClass('user-dropdown-list_hide');
    }
  })
})
