import { priceFormat, priceFormatAlt } from '../utils/priceFormat';
import { parseQueryString } from '../utils/parseQueryString';

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const isDesktop = $(window).width() > 1024;

const $map = $('.addesk-location-map');
const $mapList = $('.map-list-button');
const pin = $map.data('pin');
let placemarkObjects = [];
let geoObjectsQuery = null;
let canInteractive = false;
let pageNumber = 1;

$map.addClass('is_pending');
/** Получение минимального показателя стоим-ти в объявлениях */
Object.defineProperty(Array.prototype, 'getMinPrice', {
  get: function () {
    const prices = this.map(item => item.properties._data.priceText);
    const min = Math.min.apply(Math, prices);
    return `от ${priceFormat(min)} `;
  }
});

Object.defineProperty(Array.prototype, 'getIds', {
  get: function () {
    const ids = this.map(item => item.properties._data.markerId);
    return ids[0];
  }
});

function mapInit() {
  /** Инициализация карты */
  ymaps.ready(['ext.createCanvasOnMap', 'ext.paintOnMap']).then(function (props) {
    /** Drawer toggler */
    let checkedDraw = "unchecked";
    let selectedArea = undefined;
    let $drawPointer = $('.js-draw-pointer');
    let $currentMarker = undefined;
    let selectedMarks = undefined;
    let fullscreenControl = new ymaps.control.FullscreenControl();
    const dataCenter = $('#realty-map').data('center') || '50.257456, 127.534611';
    const mapCenter = dataCenter.split(', ');

    const map = new ymaps.Map('realty-map', {
        center: [Number(mapCenter[0]), Number(mapCenter[1])],
        zoom: 10,
        controls: [],
      },
      {
        searchControlProvider: 'yandex#search',
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true
      });
    map.behaviors.disable('multiTouch');
    map.behaviors.disable('scrollZoom');
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      /** Отключаем перетаскивание карты */
      map.behaviors.disable('drag');
    }

    /** Кластеризация маркеров на карте */
    const clusterer = new ymaps.Clusterer({
      clusterDisableClickZoom: true,
      clusterOpenBalloonOnClick: false,
      clusterIconLayout: ymaps.templateLayoutFactory.createClass(
        `<div class="custom-mark-wrap">
        <div class="custom-mark" id={{ properties.geoObjects.getIds|raw }}>
          <div class="custom-mark__number">{{ properties.geoObjects.length }}</div>
          <div class="custom-mark__price">{{ properties.geoObjects.getMinPrice|raw }}</div>
          <div class="custom-polygon"/>
        </div>
      </div>`
      ),
      clusterIconShape: {
        type: 'Rectangle',
        coordinates: [[-40, -34], [40, -2]]
      },
    });

    /** Отрисовка canvas на карте */
    let paintProcess;
    /** Опции многоугольника или линии. */
    const styles = {strokeColor: '#45B1BF', strokeOpacity: 0.7, strokeWidth: 3, fillColor: '#b1e1ea', fillOpacity: 0.4};

    let currentIndex = 0;

    /** Создадим кнопку для выбора типа рисуемого контура. */
    const ButtonLayout = ymaps.templateLayoutFactory.createClass(['<div class="draw-button"/>'].join(''));
    const button = new ymaps.control.Button({options: {layout: ButtonLayout}});
    map.controls.add(button, {});

    /** При загрузке страницы вызываем запрос на получение меток. */
    showMapRealty();

    /** Перекраска объектов, попавших в зону выделения */
    function changeMarksColor() {
      if (selectedMarks) {
        for (let i = 0; i < selectedMarks.getLength(); ++i) {
          const markId = selectedMarks.get(i).properties.get('markerId')
          if ($(`#${markId}`).length > 0) {
            $(`#${markId}`).addClass('selected-mark')
          }
        }
      }
    }

    /** Создание маркеров */
    function createPlacemarks(items) {
      if (items && items.length > 0) {
        items.forEach((el, index) => {
          const coords = el.coords;
          const customLayout = ymaps.templateLayoutFactory.createClass(
            `<div class="custom-mark-wrap">
            <div class="custom-mark" id=${el.id}>
              ${el.adsNumber > 1 ? '<div class="custom-mark__number">$[properties.iconContent]</div>' : ''}
              <div class="custom-mark__price">${priceFormat(el.priceText)}</div>
              <div class="custom-polygon"/>
            </div>
          </div>`);

          const placemark = new ymaps.Placemark([Number(coords[0]), Number(coords[1])], {
              el: el,
              markerId: el.id,
              priceText: el.priceText,
              properties: {
                markerId: el.id,
                priceText: el.priceText,
              }
            },
            {
              iconLayout: customLayout,
              iconShape: {
                type: 'Rectangle',
                params: {
                  background: 'yellow'
                },
                /** Прямоугольник описывается в виде двух точек - верхней левой и нижней правой. */
                coordinates: [[-40, -34], [40, -2]]
              }
            });

          placemarkObjects.push(placemark);

          /** Обработчик нажатия на маркер */
          placemark.events.add('click', function (e) {
            createAddeskBox({
              ids: [el.id],
              currentId: el.id
            });
          });
        });

        clusterer.add(placemarkObjects);
        map.geoObjects.add(clusterer);

        geoObjectsQuery = ymaps.geoQuery(placemarkObjects);

        /** Добавление в кластер очереди */
        clusterer.events.add('click', function (e) {
          e.stopImmediatePropagation();
          e.preventDefault();

          const target = e.get('target');
          const placemarks = target.properties._data.geoObjects;

          if (placemarks && placemarks.length < 30) {
            e.stopImmediatePropagation();
            e.preventDefault();

            const ids = placemarks.map(item => item.properties._data.markerId);

            createAddeskBox({
              ids,
              currentId: placemarks[0].properties._data.markerId
            });
          } else {
            const center = ymaps.util.bounds.getCenter(target.getBounds());
            map.setCenter(center, map.getZoom() + 1);
          }
        });

        /** Если маркеры выделены, то меняют цвет */
        if (selectedMarks) {
          changeMarksColor();

          setTimeout(() => {
            changeMarksColor();
          }, 200);
        }
      }

      /** Если присутствует выделенная облать, она помещается в список объектов */
      if (selectedArea) {
        map.geoObjects.add(selectedArea);
      }
    };


    /** Отправка отфильтрованного запроса с объявлениями на сервер */
    $('.js-mapFilterBtn').on('click', (e) => {
      e.preventDefault();
      showMapRealty();
    })

    /** Открытие модального окна на карте при нажатии на маркер */
    function createAddeskBox({ ids, currentId }) {
      const stringId = currentId;

      if (!$drawPointer.hasClass('draw-disabled')) {
        $('.custom-mark').removeClass('selected-mark');
      }

      $currentMarker = $('#' + stringId);
      $currentMarker.addClass('selected-mark');

      /** запрос фильтрованных объявлений на сервер */
      $.ajax({
        url: $('#realty-map').data('ajax-data-url'),
        data: {id: ids}
      }).done(function (data) {
        let $addesk = $('.addesk-box-small');
        let $addeskList = $('.map-list-button');
        const countAds = $(data.data).find('.desk-card').length;

        /** Подстановка кол-ва отфильтрованных объявлений в кнопку показа */
        $('.addesk-box-title').text(countAds + ' ' + pluralize(countAds, ["объявление", "объявления", "объявлений"]))

        $addesk.find('.addesk-list').replaceWith(data.data);

        if (countAds === 1) {
          $addesk.addClass('addesk-box-small__one-ad')
          if ($addesk.hasClass('addesk-box-small__visible'))
            $addeskList.addClass('map-list-button__one-ad')
        }
        if (countAds > 1 && $addesk.hasClass('addesk-box-small__one-ad')) {
          $addesk.removeClass('addesk-box-small__one-ad')
          $addeskList.removeClass('map-list-button__one-ad')
        }

        $addesk.removeClass('addesk-box-small__hidden')
        $addesk.addClass('addesk-box-small__visible')

        $mapList.removeClass('map-list-button__hidden')
        $mapList.addClass('map-list-button__visible')
      });
    }

    /** Очистка всех маркеров с карты */
    function removeMarks() {
      map.geoObjects.removeAll()

      clusterer.removeAll()
    }

    function centerMapByLocOrAddress() {
      const params = parseQueryString();
      const mapSearchValue = params['map_search'];

      if (mapSearchValue) {
        ymaps.geocode(mapSearchValue, {results: 1})
          .then(function (res) {
            const firstGeoObject = res.geoObjects.get(0);
            const coords = firstGeoObject.geometry.getCoordinates();

            map.setCenter(coords);
          });
      } else {
        userGeolocation();
      }
    }

    /** Отрисовка маркеров на карте */
    function showMapRealty () {
      const ajaxUrl = $('#realty-map').data('ajax-url');
      const formData = $('.filter').serializeArray();

      $.ajax({
        url: ajaxUrl,
        data: formData
      }).done(function (data) {
        removeMarks();
        if (data && data.length > 0) {
          createPlacemarks(data);
          refreshVisibleList();
          getMarkersAjax();
        }
        centerMapByLocOrAddress();
        closeModal();
        setTimeout(() => {
          canInteractive = true;
        }, 5000);
      }).always(function () {
        $map.removeClass('is_pending');
      });

    }

    /** Получение маркеров с сервера */
    function getMarkersAjax () {
      const ajaxUrl = $('#realty-map').data('ajax-url');
      const formData = $('.filter').serializeArray();

      if (window.location.host === 'localhost:3000') return;

      pageNumber = pageNumber + 1;

      $.ajax({
        url: ajaxUrl,
        data: [...formData, {
          name: 'PAGEN_1', value: pageNumber
        }]
      }).done(function (data) {
        if (data && data.length > 0) {
          createPlacemarks(data);
          getMarkersAjax();
        }
      });
    }

    /** Обработчик изм-я позиции карты */
    map.events.add('boundschange', function (e) {
      let state = map.action.getCurrentState();
      let newZoom = e.get('newZoom')
      let oldZoom = e.get('oldZoom')
      let oldBounds = e.get('oldBounds')
      let newBounds = e.get('newBounds')
      let newCenter = e.get('target').getCenter()

      if (newZoom !== oldZoom || newBounds !== oldBounds) {
        refreshVisibleList();
      }

      if ($drawPointer.hasClass('draw-disabled')) {
        changeMarksColor();

        setTimeout(() => { changeMarksColor(); })
      }
    });
    window.refreshVisibleList = function (filteredObjects) {

      /** С помощью geoQuery получаем список объектов на карте. */
     if (geoObjectsQuery) {
        let selectedObjects = geoObjectsQuery;

        $('.js-filterSelectedAreaIds').val('');

        if ($drawPointer.hasClass('draw-disabled')) {
          selectedObjects = selectedMarks;
          const selectedIds = selectedMarks && selectedMarks._objects.map(item => item.properties._data.markerId) || [];
          $('.js-filterSelectedAreaIds').val(selectedIds.join(','));
        };

        const visibleGeoObjects = selectedObjects.searchIntersect(map);
        let addCounter = visibleGeoObjects.getLength()

        if (filteredObjects) {
          addCounter = filteredObjects.length;
        }

        if (addCounter < 1) {
          $('.map-list-button').attr('href')
          $('.js-submitFilterBtn .button__text').text("Ничего не найдено")
          $('.js-submitFilterBtn').prop('disabled', true)
        } else {
          $('.js-submitFilterBtn .button__text').text("Показать " + priceFormatAlt(addCounter) + ' ' + pluralize(addCounter, ["объявление", "объявления", "объявлений"]))
          $('.js-submitFilterBtn').removeAttr('disabled')
        }
        $('.js-locationAddressInput').trigger('focusout')
      }

    }

    const ZoomLayout = ymaps.templateLayoutFactory.createClass("<div/>", {
      build: function () {
        /** Вызываем родительский метод build. */
        ZoomLayout.superclass.build.call(this);

        /** Привязываем функции-обработчики к контексту и сохраняем ссылки
         * на них, чтобы потом отписаться от событий. */
        this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
        this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

        /** Начинаем слушать клики на кнопках макета. */
        $('#zoom-in').bind('click', this.zoomInCallback);
        $('#zoom-out').bind('click', this.zoomOutCallback);
      },

      clear: function () {
        /** Снимаем обработчики кликов. */
        $('#zoom-in').unbind('click', this.zoomInCallback);
        $('#zoom-out').unbind('click', this.zoomOutCallback);

        /** Вызываем родительский метод clear. */
        ZoomLayout.superclass.clear.call(this);
      },

      /** Зум + */
      zoomIn: function () {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
      },

      /** Зум - */
      zoomOut: function () {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
      }
    });
    const zoomControl = new ymaps.control.ZoomControl({
      options: {layout: ZoomLayout}
    });
    map.controls.add(zoomControl);

    /** Линейка */
    let rulerClick = 0
    $('.js-ruler').on('click', () => {
      ++rulerClick
      if (rulerClick === 1) {
        if (!(map.behaviors.isEnabled('ruler'))) {
          map.behaviors.enable("ruler");
        }
        $('.nav-ruler').addClass('draw-checked')
        $drawPointer.attr('disabled', 'disabled')
        map.behaviors.disable('multiTouch');
        map.behaviors.disable('scrollZoom');
      }
      if (rulerClick === 2) {
        map.behaviors.disable("ruler");
        rulerClick = 0
        if ($('.nav-ruler').hasClass('draw-checked')) {
          $drawPointer.removeAttr('disabled');
          $('.nav-ruler').removeClass('draw-checked')
        }
      }
    });

    /** Геолокация пользователя */
    $('.js-navigation').on('click', () => {
      userGeolocation()
    })

    function userGeolocation () {
      const geoloc = ymaps.geolocation;
      canInteractive = false;
      geoloc.get({
        provider: 'browser',
        mapStateAutoApply: true
      }).then(function (result) {
        result.geoObjects.options.set('preset', pin);
        // map.geoObjects.add(result.geoObjects);

        const geoCoords = [
          Number(result.geoObjects.position[0]),
          Number(result.geoObjects.position[1])
        ];
        const geoBounds = result.geoObjects.get(0).properties.get('boundedBy')
        const geolocMark = new ymaps.Placemark(geoCoords, {}, {
          iconLayout: 'default#image',
          iconImageHref: pin,
          iconImageSize: [35, 42],
          iconImageOffset: [-15, -42]
        });
        map.geoObjects.add(geolocMark);
        map.setBounds(geoBounds, {
          checkZoomRange: true
        }).then(function () {
            canInteractive = true;
          });
      });
    }

    /** Toggle draw pointer button (checked/unchecked/disabled) */
    let clickCounter = 0;
    $drawPointer.on('click', () => {
      ++clickCounter;

      /** Если кнопка рисования на нажата */
      if (checkedDraw === "unchecked" && clickCounter === 1) {
        checkedDraw = "checked"

        ymaps.ext.createCanvasOnMap(map, styles);
        map.events.add(["mousedown"], (e) => handleMouseDown(e));
        map.events.add(["touchstart"], (e) => handleMouseDown(e));

        setTimeout(() => {
          map.events.add(["mouseup"], (e) => handleMouseUp(e));
          map.events.add(["touchend"], (e) => handleMouseUp(e));
        })

        $drawPointer.addClass('draw-checked')
        $('.nav-ruler').attr('disabled', 'disabled')

        if ($(window).width() <= 1024) {
          $('body').addClass('is-drawing-mode');
          map.container.enterFullscreen();
          setTimeout(() => {
            if ($('#canvas').length > 0) {
              disableBodyScroll('#canvas');
            }
          });
        }
      }

      /** Кнопка рисования нажата */
      if ($drawPointer.hasClass('draw-checked') && checkedDraw === "checked" && clickCounter === 2) {
        $drawPointer.removeClass('draw-checked')
        $drawPointer.addClass('draw-unchecked')
        $('.nav-ruler').removeAttr('disabled');
        clickCounter = 0;
        checkedDraw = "unchecked"

        if (selectedArea) {
          map.geoObjects.remove(selectedArea);
        }

        if (selectedMarks) {
          for (let i = 0; i < selectedMarks.getLength(); ++i) {
            const markId = selectedMarks.get(i).properties.get('markerId')
            if ($('#' + markId) && !$currentMarker) {
              $('#' + markId).removeClass('selected-mark')
            }
          }
        }
        $('#canvas').remove()
      }

      /** Кнопка рисования нажата, рисование совершено */
      if ($drawPointer.hasClass('draw-disabled')) {
        checkedDraw = "unchecked"
        $drawPointer.removeClass('draw-disabled')
        $drawPointer.addClass('draw-unchecked');
        $('.nav-ruler').removeAttr('disabled');
        clickCounter = 0;

        /** Удаление выделенной области и первоначальное отображение маркеров */
        map.geoObjects.remove(selectedArea);

        $('.js-locationAddressInput').val('');
        $('.js-filterSelectedAreaIds').val('');

        $('.custom-mark').removeClass('selected-mark');
        $('.close-addesk-box').trigger('click');
      }
    });

    function pluralize(count, words) {
      var cases = [2, 0, 1, 1, 1, 2];
      return words[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)]];
    }

    /** Закрытие модального окна с объявлениями */
    $('.close-addesk-box').on('click', () => {
      closeModal()
    })

    function closeModal() {
      if ($('.addesk-box-small').hasClass('addesk-box-small__visible')) {
        $('.addesk-box-small').removeClass('addesk-box-small__visible')
        $mapList.addClass('map-list-button__visible')
      }
      $('.addesk-box-small').addClass('addesk-box-small__hidden')
      $mapList.addClass('map-list-button__hidden')
      if ($currentMarker) {
        $currentMarker.removeClass('selected-mark')
      }
    }

    /** Старт рисования */
    function handleMouseDown(e) {
      if (checkedDraw === "checked") {
        // if ($(window).width() <= 1024) {
        //   $('body').addClass('is-drawing-mode');
        //   disableBodyScroll('#canvas');
        // }
        $('.js-locationAddressInput').val('Область на карте').attr('data-value', 'Область на карте');
        paintProcess = ymaps.ext.paintOnMap(map, e, {style: styles});
      } else {
        return
      }
    }

    /** Удаление выделенной области */
    const removeSelection = () => {
      checkedDraw = "disabled";
      $drawPointer.removeClass('draw-checked');
      $drawPointer.addClass('draw-disabled');
      clickCounter = 1;
    }

    /** Завершение рисования */
    function handleMouseUp(e) {
      if (paintProcess) {
        const coordinates = paintProcess.finishPaintingAt(e);
        paintProcess = null;

        selectedArea = button.isSelected()
          ? new ymaps.Polyline(coordinates, {}, styles)
          : new ymaps.Polygon([coordinates], {}, styles);

        map.geoObjects.add(selectedArea);

        selectedMarks = ymaps.geoQuery(placemarkObjects).searchInside(selectedArea);

        /** Если выделенная область захватила маркеры */
        if (selectedMarks.getLength()) {
          map.setBounds(selectedMarks.getBounds(), {
            checkZoomRange: true
          }).then(function () {
            setTimeout(() => {
              changeMarksColor();
            }, 500);
          });
        }
        removeSelection()
        refreshVisibleList()

        checkedDraw = "disabled"
        $drawPointer.removeClass('draw-checked')
        $drawPointer.addClass('draw-disabled')
        clickCounter = 1;

        if ($(window).width() <= 1024) {
          map.container.exitFullscreen();
          $('body').removeClass('is-drawing-mode');
          if ($('#canvas').length > 0) {
            enableBodyScroll('#canvas');
          }
        }
        map.container.fitToViewport();

        map.behaviors.disable('multiTouch');
        map.behaviors.disable('scrollZoom');
        clickCounter = 0;
      }
    }

    $('.js-mapInput').on('change', (e) => {
      if($('.js-mapInput').val() === '') {
        selectedMarks = [];
        $('.js-searchFormClear').trigger('click')
      }
    })

    /** Фильтр для нарисованной области на карте */
    $('.filter input').on('change', filterForSelectedArea);

    function filterForSelectedArea() {
      if (selectedMarks) {
        const filteredMarkers = [];

        const $filter = $('.filter');
        const filterCategory = $('[name="category"]:checked', $filter).val();
        const filterRoomsCount = $('[name="rooms_count"]:checked', $filter).val();
        const filterRent = $('[name="rent"]:checked', $filter).val();
        const filterWallType = getCheckedValues($('[name="wall_type[]"]:checked', $filter));
        const filterRepair = getCheckedValues($('[name="repair[]"]:checked', $filter));
        const filterHouseType = getCheckedValues($('[name="house_type[]"]:checked', $filter));
        const filterSeller = getCheckedValues($('[name="seller[]"]:checked', $filter));
        const filterGarageType = getCheckedValues($('[name="garage_type[]"]:checked', $filter));
        const filterGroundType = getCheckedValues($('[name="ground_type[]"]:checked', $filter));
        const filterHeating = getCheckedValues($('[name="heating[]"]:checked', $filter));
        const filterCommercialRealty = getCheckedValues($('[name="commercial_realty[]"]:checked', $filter));
        const filterMortgage = $('[name="mortgage"]:checked', $filter).val();
        const filterOnlyPhoto = $('[name="only_photo"]:checked', $filter).val();
        const filterFurniture = $('[name="furniture"]:checked', $filter).val();
        const filterWell = $('[name="well"]:checked', $filter).val();
        const filterElectro = $('[name="electro"]:checked', $filter).val();
        const filterDistance = $('[name="distance"]:checked', $filter).val();
        const filterHomeExist = $('[name="home_exist"]:checked', $filter).val();
        const filterBalcony = $('[name="balcony"]:checked', $filter).val();
        const filterLoggia = $('[name="loggia"]:checked', $filter).val();
        const filterFloor = getCheckedValues($('[name="floor[]"]:checked', $filter));
        const filterAreaFrom = $('[name="area_from"]', $filter).val();
        const filterAreaTo = $('[name="area_to"]', $filter).val();
        const filterPriceFrom = $('[name="price_from"]', $filter).val();
        const filterPriceTo = $('[name="price_to"]', $filter).val();
        const filterKitchenAreaFrom = $('[name="kitchen_area_from"]', $filter).val();
        const filterKitchenAreaTo = $('[name="kitchen_area_to"]', $filter).val();
        const filterFloorFrom = $('[name="floor_from"]', $filter).val();
        const filterFloorTo = $('[name="floor_to"]', $filter).val();
        const filterGateFrom = $('[name="gate_from"]', $filter).val();
        const filterGateTo = $('[name="gate_to"]', $filter).val();
        const filterSquareFrom = $('[name="square_from"]', $filter).val();
        const filterSquareTo = $('[name="square_to"]', $filter).val();

        if (selectedMarks._objects.length > 0) {
          selectedMarks._objects.forEach((item) => {
            const el = item.properties._data.el;

            if (
              (filterCategory === el.category) &&
              (filterRoomsCount ? Number(filterRoomsCount) === Number(el.rooms_count) : true) &&
              (filterRent ? filterRent === el.rent : true) &&
              (filterWallType.length > 0 ? filterWallType.includes(el.wall_type) : true) &&
              (filterRepair.length > 0 ? filterRepair.includes(el.repair) : true) &&
              (filterHouseType.length > 0 ? filterHouseType.includes(el.house_type) : true) &&
              (filterSeller.length > 0 ? filterSeller.includes(el.seller) : true) &&
              (filterGarageType.length > 0 ? filterGarageType.includes(el.garage_type) : true) &&
              (filterGroundType.length > 0 ? filterGroundType.includes(el.ground_type) : true) &&
              (filterHeating.length > 0 ? filterHeating.includes(el.heating) : true) &&
              (filterCommercialRealty.length > 0 ? filterCommercialRealty.includes(el.commercial_realty) : true) &&
              (filterMortgage ? filterMortgage === el.mortgage : true) &&
              (filterOnlyPhoto ? el.photo?.length > 0 : true) &&
              (filterFurniture ? filterFurniture === el.furniture : true) &&
              (filterWell ? filterWell === el.well : true) &&
              (filterElectro ? filterElectro === el.electro : true) &&
              (filterDistance ? String(filterDistance) === String(el.distance) : true) &&
              (filterHomeExist ? filterHomeExist === el.home_exists : true) &&
              (filterBalcony ? filterBalcony === el.balcony : true) &&
              (filterLoggia ? filterLoggia === el.loggia : true) &&
              (filterFloor.length > 0 ? filterFloor.includes(el.floor_not_first) : true) &&
              (filterAreaFrom ? Number(el.area) >= Number(filterAreaFrom) : true) &&
              (filterAreaTo ? Number(el.area) <= Number(filterAreaTo) : true) &&
              (filterPriceFrom ? Number(el.price) >= Number(filterPriceFrom) : true) &&
              (filterPriceTo ? Number(el.price) <= Number(filterPriceTo) : true) &&
              (filterKitchenAreaFrom ? Number(el.kitchen_area) >= Number(filterKitchenAreaFrom) : true) &&
              (filterKitchenAreaTo ? Number(el.kitchen_area) <= Number(filterKitchenAreaTo) : true) &&
              (filterFloorFrom ? Number(el.floor) >= Number(filterFloorFrom) : true) &&
              (filterFloorTo ? Number(el.floor) <= Number(filterFloorTo) : true) &&
              (filterGateFrom ? Number(el.gate_height) >= Number(filterGateFrom) : true) &&
              (filterGateTo ? Number(el.gate_height) <= Number(filterGateTo) : true) &&
              (filterSquareFrom ? Number(el.square) >= Number(filterSquareFrom) : true) &&
              (filterSquareTo ? Number(el.square) <= Number(filterSquareTo) : true)
            ) {
              filteredMarkers.push(el);
            }
          });
        }
        removeMarks();
        placemarkObjects = [];
        setTimeout(() => {
          createPlacemarks(filteredMarkers);
          refreshVisibleList(filteredMarkers);
        });
      }
    }

    function getCheckedValues(items) {
      const checked = [];

      if (typeof items !== 'undefined' && items.length) {
        items.each((index, el) => {
          checked.push($(el).val());
        });
      }

      return checked;
    }

    $('.addesk-location-map').on('click', () => {
      map.behaviors.enable('multiTouch');
      map.behaviors.enable('scrollZoom');
      map.behaviors.enable('drag');
    })
  });
}

ymaps.ready(mapInit);

$(document).ready(function () {
  /** Блокировка карты при пустом фильтре */
  if ($('.js-submitFilterBtn .button__text').text() === "Ничего не найдено") {
    $('.js-submitFilterBtn').prop('disabled', true)
  }
})
