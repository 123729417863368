const $mainSlider = $('#js-main-slider');
const $mainWrapper = $('.main-content-wrap');
let imagesSliderApi = null;
let textSliderApi = null;
let rubricSliderApi = null;
let numOfElement = 0

const baseConfig = {
  loop: true,
  speed: 1000,
  observer: true,
  observeSlideChildren: true,
  observeParents: true,
  spaceBetween: 10,
  noSwipingClass: 'swiper-slide',
}

$(window).on('resize load', () => {
  $('#js-main-slider').slick('slickPlay');
  if ($mainSlider.length > 0) {
    mainSlidersUpdate();
  }
});

setTimeout(() => {
  if ($mainSlider.length > 0) {
    mainSlidersUpdate();
  }
}, 2500)

export function mainSlidersSlideByIndex(index) {
  const apis = [imagesSliderApi, textSliderApi, rubricSliderApi];

  $.each(apis, (inx, el) => el.slideToLoop(index));
}

export function mainSlidersSlideByDir(direction) {
  const apis = [imagesSliderApi, textSliderApi, rubricSliderApi];

  if (direction === 'next') {
    $.each(apis, (inx, el) => {
      el.slideNext()
    });
  }
  if (direction === 'prev') {

    $.each(apis, (inx, el) => {
      el.slidePrev()
    });
  }
}

export function mainSlidersUpdate() {
  const apis = [imagesSliderApi, textSliderApi, rubricSliderApi];
    console.log('apis', apis)
    $.each(apis, (inx, el) => {
      console.log('index', inx)
      if (el) {
        el.update(true)
      }
    });
}

export function mainSliderCreate() {
  imagesSliderCreate();
  textSliderCreate();
  rubricSliderCreate();
}

export function mainSliderInit() {
  const $mainImagesSlider = $('.js-mainImagesSlider');
  const $mainTextSlider = $('.js-mainTextSlider');
  const $mainRubricSlider = $('.js-mainRubricSlider');

  imagesSliderApi = new Swiper($('.swiper-container', $mainImagesSlider), {
    ...baseConfig,
    parallax: true,
    spaceBetween: 0,
  });

  textSliderApi = new Swiper($('.swiper-container', $mainTextSlider), {
    ...baseConfig,
    direction: 'vertical',
  });

  rubricSliderApi = new Swiper($('.swiper-container', $mainRubricSlider), {
    ...baseConfig,
    direction: 'vertical',
  });
}

function imagesSliderCreate() {
  const imagesSlider = `<div class="main-images-slider js-mainImagesSlider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
      </div>
    </div>
  </div>`;

  $mainWrapper.append(imagesSlider);

  const $mainImagesSlider = $('.js-mainImagesSlider');

  $('.main-slide', $mainSlider).each((index, element) => {
    const image = $(element).find('.main-slide__image').clone();

    image.attr('data-swiper-parallax', '50%');

    $('.swiper-wrapper', $mainImagesSlider)
      .append('<div class="swiper-slide"></div>');

    $('.swiper-slide', $mainImagesSlider)
      .eq(index).append(image);
  });
}

function textSliderCreate() {
  const textSlider = `<div class="main-text-slider js-mainTextSlider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
      </div>
    </div>
  </div>`;

  $mainWrapper.append(textSlider);

  const $mainTextSlider = $('.js-mainTextSlider');

  $('.main-slide', $mainSlider).each((index, element) => {
    const text = $(element).find('.main-slide__title').clone();

    $('.swiper-wrapper', $mainTextSlider)
      .append('<div class="swiper-slide"></div>');

    $('.swiper-slide', $mainTextSlider)
      .eq(index).append(text);
  });
}

function rubricSliderCreate() {
  const rubricSlider = `<div class="main-rubric-slider js-mainRubricSlider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
      </div>
    </div>
  </div>`;

  $mainWrapper.append(rubricSlider);

  const $mainRubricSlider = $('.js-mainRubricSlider');

  $('.main-slide', $mainSlider).each((index, element) => {
    const text = $(element).find('.main-slide__rubrics').clone();
    numOfElement = index
    $('.swiper-wrapper', $mainRubricSlider)
      .append('<div class="swiper-slide"></div>');

    $('.swiper-slide', $mainRubricSlider)
      .eq(index).append(text);
  });
}
