ymaps.ready(function () {
  const $map = $('.addesk-detail-map');
  const pin = $map.data('pin');
  if ($map.data('center') !== undefined) {
    const center = $map.data('center').split(', ');
    const centerCoords = [Number(center[0]), Number(center[1])];

    /** Инициализация карты */
    let map = new ymaps.Map('detail-map', {
      center: centerCoords,
      zoom: 9,
      controls: []
    }, {
      yandexMapDisablePoiInteractivity: true,
      suppressMapOpenBlock: true //отключение ссылки «Открыть в Яндекс.Картах»
    });

    /** Создание маркеров на карте */
    const mapPlacemark = new ymaps.Placemark(centerCoords, {}, {
      iconLayout: 'default#image',
      iconImageHref: pin,
      iconImageSize: [35, 42],
      iconImageOffset: [-15, -42]
    });
    map.geoObjects.add(mapPlacemark);

    /** Геолокация пользователя */
    $('.js-navigation').on('click', () => {
      const geoloc = ymaps.geolocation;
      geoloc.get({
        provider: 'yandex',
        mapStateAutoApply: true
      }).then(function (result) {
        console.log('result', result)
        result.geoObjects.options.set('preset', pin);
        map.geoObjects.add(result.geoObjects);
        console.log('result', result)
        const geoCoords = [
          Number(result.geoObjects.position[0]),
          Number(result.geoObjects.position[1])
        ];
        const geoBounds = result.geoObjects.get(0).properties.get('boundedBy')
        const geolocMark = new ymaps.Placemark(geoCoords, {}, {
          iconLayout: 'default#image',
          iconImageHref: pin,
          iconImageSize: [35, 42],
          iconImageOffset: [-15, -42]
        });
        map.geoObjects.add(geolocMark);
        map.setBounds(geoBounds, {
          checkZoomRange: true
        });
      });
    })

    /** Обработчик увеличения/уменьшения карты */
    const ZoomLayout = ymaps.templateLayoutFactory.createClass("<div/>", {
      build: function () {
        /** Вызываем родительский метод build */
        ZoomLayout.superclass.build.call(this);

        /** Привязываем функции-обработчики к контексту и сохраняем ссылки
         * на них, чтобы потом отписаться от событий. */
        this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
        this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

        /** Начинаем слушать клики на кнопках макета. */
        $('#zoom-in_detail').bind('click', this.zoomInCallback);
        $('#zoom-out_detail').bind('click', this.zoomOutCallback);
      },

      clear: function () {
        /** Снимаем обработчики кликов. */
        $('#zoom-in_detail').unbind('click', this.zoomInCallback);
        $('#zoom-out_detail').unbind('click', this.zoomOutCallback);

        /** Вызываем родительский метод clear. */
        ZoomLayout.superclass.clear.call(this);
      },

      zoomIn: function () {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() + 1, {
          checkZoomRange: true
        });
      },

      zoomOut: function () {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() - 1, {
          checkZoomRange: true
        });
      }
    });
    const zoomControl = new ymaps.control.ZoomControl({
      options: {
        layout: ZoomLayout
      }
    });
    map.controls.add(zoomControl);
  }
});
