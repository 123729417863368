/** Ловим клик по ссылке с классом go_to */
$('.js-scrollToElem').click(function () {
  /** Возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или. */
  var scroll_el = $(this).attr('href');
  /** Проверим существование элемента чтобы избежать ошибки */
  if ($(scroll_el).length != 0) {
    /** Анимируем скроолинг к элементу scroll_el */
    $('html, body').animate({scrollTop: $(scroll_el).offset().top - 99}, 500);
  }
  /** выключаем стандартное действие */
  return false;
});
