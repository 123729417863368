const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

let timer = ''
let interval = null
let buttonCodeCounter = 0

let firstTime = 0
let lastTime = 0
let isIOS13 = /(iphone|ipad|ipod)/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

/** Отключение закрытия окна на нажатие по области вне его для  modal-confirm-success */
if($('.js-successConfirmModal').length) {
  $('.js-successConfirmModal').magnificPopup({
    closeOnBgClick: false,
    closeBtnInside: false,
  });
}


$.extend(true, $.magnificPopup.defaults, {
  tClose: 'Закрыть (Esc)',
  tLoading: 'Загружается...',
  gallery: {
    tPrev: 'Предыдущая (←)',
    tNext: 'Следующая (→)',
    tCounter: '%curr% из %total%'
  },
  image: {
    tError: '<a href="%url%">Данная картинка</a> не может быть загружена.'
  },
  ajax: {
    tError: '<a href="%url%">Содержимое</a> не может быть загружено.'
  },
  closeMarkup: '<div class="mfp-close js-mfp-close"></div>',
  removalDelay: 0,
  closeBtnInside: false,
  fixedContentPos: true,
  autoFocusLast: false,
  closeOnBgClick: true,
});

/** Открытие модального окна */
function modalOpen(src, type) {
  $.magnificPopup.open({
    items: {
      src: src,
      type: type
    },
    closeBtnInside: true,
    callbacks: {
      open: function () {
        $('html').addClass('mfp-open');
        if (!isIOS13) {
          disableBodyScroll('.mfp-wrap');
        }

      },
      close: function () {
        $('html').removeClass('mfp-open');
        if (!isIOS13) {
          enableBodyScroll('.mfp-wrap');
        }
      }
    }
  });
}

/** Закрытие окна по нажатию на иконку закрытия */
$(document).on('click', '.js-modalClose', function () {
  if (!$('.js-successConfirmModal')) {
    $.magnificPopup.close();
  }
});


$(document).on('click', '.js-modalLink', (event) => {
  const _this = $(event.currentTarget);
  const src = _this.data('mfp-src') || _this.attr('href');
  const type = _this.data('mfp-ajax') || 'inline';

  event.preventDefault();

  modalOpen(src, type);
});

window.modalOpen = function (modal) {
  modalOpen(modal);
}

$('.confirm-input').keyup(function (e) {
  if ($(this).val().match(/^\d{1}$/)) {
    $(this).parents('.confirm-code-field').next().find('.confirm-input').focus();
  } else {
    $(this).val('');
  }
});

/** Показ/скрытие ошибки */
window.showError = function () {
  $('.confirm-input').addClass('error-form')
  $('.confirm-error-message').removeClass('js-hideConfirm')
}

window.hideError = function () {
  $('.confirm-input').removeClass('error-form')
  $('.confirm-error-message').addClass('js-hideConfirm')
}

/** Счётчик таймера */
window.timerOn = function () {
  timer -= 1000
  let min = timer / 1000 / 60;
  let r = min % 1;
  let sec = Math.floor(r * 60);
  if (sec < 10) {
    sec = '0' + sec;
  }
  min = Math.floor(min);

  if (min > 9) {
    $('.confirm-timer-text').text(min + ':' + sec)
  } else {
    $('.confirm-timer-text').text('0' + min + ':' + sec)
  }

  if (timer <= 0) {
    resubTimeout()
  }
}

/** нажатие кнопки повторного запроса кода */
$('.resub-btn').on('click', () => {
  if (buttonCodeCounter < 2) {
  }
  if (buttonCodeCounter === 2) {
    sendCode(300000)
  }
  if (buttonCodeCounter === 3) {
    sendCode(1800000)
    buttonCodeCounter = 0
  }

  buttonCodeCounter++
  localStorage.setItem('buttonCodeCounter', String(buttonCodeCounter));
  $('.js-confirmAd').prop('disabled', true)
})

/** Ограничение на повторый запрос кода */
window.sendCode = function (time) {
  if (time == 300000) {
    $('.confirm-error-message_text').text('Вы ввели неверный код. Повторная попытка возможна через 5 мин')
  }
  if (time == 1800000) {
    $('.confirm-error-message_text').text('Вы дважды ввели неверный код. Повторная попытка возможна через 30 мин')
  }
  timer = time
  recordTime(timer)
  intervalFunc()
  setTimeout(resubTimeout, timer)

  showTimeout()
}

const intervalFunc = () => {
  interval = setInterval(timerOn, 1000)
}

window.recordTime = function (time) {
  /** Текущее время */
  firstTime = new Date().getTime();
  /** Время после 5 мин */
  lastTime = firstTime + time;

  localStorage.setItem('firstTime', String(firstTime));
  localStorage.setItem('lastTime', String(lastTime));
}

/** Показ таймера на странице */
window.showTimeout = function () {
  $('.confirm-timer-text').text('')
  $('.resub-tmr').removeClass('js-hideConfirm')
  $('.resub-btn').addClass('js-hideConfirm')
}

/** Сброс счётчика таймера */
window.resubTimeout = function () {
  $('.resub-btn').removeClass('js-hideConfirm')
  $('.resub-tmr').addClass('js-hideConfirm')
  clearInterval(interval)
  $('.js-confirmAd').removeAttr('disabled')
}

/** Запуск таймера при обновлении страницы */
$(document).ready(function () {
  firstTime = new Date().getTime(); //текущее время
  lastTime = localStorage.getItem('lastTime')
  buttonCodeCounter = Number(localStorage.getItem('buttonCodeCounter'))

  /** Если время ещё не прошло */
  if (firstTime < lastTime) {
    timer = lastTime - firstTime
    showTimeout()
    intervalFunc()
  } else {
    resubTimeout()
  }
});

window.clearInput = function () {
  $(".confirm-input").each(function () {
    $(this).val('')
  });
}



