const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

let isIOS13 =/(iphone|ipad|ipod)/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

/** Бургер меню, его открытие/закрытие при адаптиве */
$(function() {

  $('#button-go-back').click(function(){
    $('#desk-menu-list').hide(100);
    $('#main-menu-m-list').show(100);
    $('#button-go-back').hide(100)
  })

  $('#js-bugrger-button').click(function(){
    $('body').addClass('is-menu-open');
    if(!isIOS13){
      disableBodyScroll('.mobile-menu');
    }
    $('#menu-mobile').slideDown(300);
    $('#menu-mobile').css({
      display: 'flex'
    })
  })

  $('#close-mobile-menu').click(function(){
    $('body').removeClass('is-menu-open');
    $('#menu-mobile').slideUp(300);

    if(!isIOS13){
      enableBodyScroll('.mobile-menu');
    }
  })

  $('#js-show-desk-menu').click(function(e){
    $('body').addClass('is-menu-open');
    e.preventDefault();
    if(!isIOS13){
      disableBodyScroll('.mobile-menu');
    }
    $('#main-menu-m-list').hide(100);
    $('#desk-menu-list').show(100);
    $('#button-go-back').show(100)
  })
})
