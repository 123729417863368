const $filter = $('.filter');
const $filterShadow = $('.js-filterShadow');
const $filterBorderLine = $('.js-filterBorderLine');
const $filterToggle = $('.js-filterToggle');
const $filterToggleTitle = $('.js-filterToggleTitle');
const $buttonScroll = $('.js-scrollTopFilter');

if ($filter.length > 0) {
  /** Нажатие кнопки показа расширенных фильтров */
  $filterToggle.on('click', (e) => {
    e.preventDefault();

    $filter.toggleClass('filter_all_visible');

    ($filter.hasClass('filter_all_visible'))
      ? $filterToggleTitle.text('Скрыть параметры')
      : $filterToggleTitle.text('Показать ещё параметры')
  });

  /** Стилизация фильтра */
  function position() {
    const $container = $('.filter');
    const offset = $container.offset().left;

    $filterShadow.css({
      opacity: 0.2,
      left: -offset,
      right: -offset,
    });

    $filterBorderLine.css({
      left: -offset,
      right: -offset,
    });
  };

  $(window).on('load resize', position);

  $buttonScroll.on('click', () => {
    $('html, body').animate({
      scrollTop: 0
    });
  });
}

/** left gradient on breadcrumbs */
$scrollElem = $('.breadcrumb__list')
$scrollElem.on('touchmove', function () {
  if ($scrollElem.scrollLeft() > 0) {
    $(this).prev().fadeIn();
  }
})

$scrollElem.on('touchend', function () {
  if ($scrollElem.scrollLeft() === 0) {
    $(this).prev().fadeOut();
  }
})

$towns = $('.js-towns');
$address = $('.js-address');
$addrInput = $('.js-enterAddress');
$dropList = $('.dropdown-map-list');

/** Отправка запроса на получение адреса */
const sendAddress = () => {
  const ajaxUrl = '/ajax/addressHelper.php';
  const $formData = $('.js-enterAddress').val();

  if ($formData === '') {
    if ($dropList.hasClass('dropdown-map-list__visible')) {
      $dropList.removeClass('dropdown-map-list__visible');
    }
  } else {
    $.ajax({
      method: 'POST',
      url: ajaxUrl,
      data: {address: $formData}
    }).done(function (data) {
      const {addresses, towns} = data;

      $address.html('');
      $towns.html('');

      /** Вывод отфильтрованных параметров */
      if (addresses.length > 0) {
        addresses.forEach(function (el) {
          $address.append('<li class="dropdown-map-list__cities-item js-addressLinkButton" data-value="' + el.input + '">' + el.show + '</li>');
        });
      } else {
        $address.append('<li class="dropdown-map-list__cities-item" style="pointer-events: none;">Ничего не найдено</li>');
      }

      if (towns.length > 0) {
        towns.forEach(function (el) {
          $towns.append('<li class="dropdown-map-list__cities-item js-addressLinkButton" data-value="' + el.input + '">' + el.show + '</li>');
        });
      } else {
        $towns.append('<li class="dropdown-map-list__cities-item" style="pointer-events: none;">Ничего не найдено</li>');
      }

      if ($addrInput.is(':focus')) {
        $dropList.addClass('dropdown-map-list__visible');
      }
    });
  }
}

/** Фиксированный запрос на сервер */
function debounce(f, t) {
  return function (args) {
    let previousCall = this.lastCall;
    this.lastCall = Date.now();
    if (previousCall && ((this.lastCall - previousCall) <= t)) {
      clearTimeout(this.lastCallTimer);
    }
    this.lastCallTimer = setTimeout(() => f(args), t);
  }
}

$addrInput.on('input', debounce(sendAddress, 500));

$addrInput.on('focus', () => {
  sendAddress()
})

/** Потеря фокуса с инпута */
$addrInput.on('blur', (e) => {
  if ($('.js-enterAddress').attr('data-value') !== $('.js-enterAddress').val()) {
    $addrInput.val('');
    $(e.currentTarget).parents('form').trigger('input');
  }
})

/** Обработчик нажатия на область вне инпута */
$(document).on('click', (e) => {
  const $el = $addrInput;
  if ($el) {
    if (!$el.is($(e.target))) {
      if ($('.js-enterAddress').attr('data-value') !== $('.js-enterAddress').val()) {
        $addrInput.val('');
      }
      $dropList.removeClass('dropdown-map-list__visible');
    }
  }
})

$('.aside-nav__elem').on('click', (e) => {
  $('.aside-nav__elem').removeClass('is_active');
  $('.aside-nav__elem').removeClass('prev');

  $(e.currentTarget).prev().addClass('prev');
  $(e.currentTarget).addClass('is_active');
})

$('.js-clearFilterBtn').on('click', () => {
  $('.js-searchFormClear').removeClass('is_visible_clear')
})
