$(function () {
  /** Таймер скрытия/показа пкнопки */
  const degDefault = 90;
  const coeff = 0.17;
  let isScrolled;
  let isScrollDown;
  let scrollPos = 0;

  setTimeout(() => {
    $('.js-slide-down').addClass('news-section__header_animated')
    $('#js-rotate-arrow').data('scrollTop', 0);
  }, 500)
})


scrollableButton();

$(window).on('scroll load', scrollableButton);

let scrollPos = 0;

/** Показ кнопки только по скроллу снизу-вверх */
function scrollableButton() {
  let isMobile = $(window).width() < 600;

  if(isMobile) {
    let st = $(this).scrollTop();
    if (st < 200) {
      $('.js-scrollableButton').show();
    }
    if (st > scrollPos && st > 0) {
      /** Вниз */
      $('.js-scrollableButton').hide();
    } else {
      /** Вверх */
      $('.js-scrollableButton').show();
    }

    scrollPos = st;
  }
}


$(document).ready(function () {
  $(document).on('copy paste', '.js-noCopyPasteField', function (e) { e.preventDefault(); });
  // const myInput = document.getElementById('myInput');
  // myInput.onpaste = e => e.preventDefault();
})

