$(document).ready( function() {
  /** Обработчик нажатия на элемент списка объявлений */
	$('.desk-nav-list').on('click', 'li:not(.desk-nav-list__item_active)', function() {
		const _this = $(this);

		/** Получение индекса текущего элемента */
		const currentTab = $('.desk-section__content').find('.desk-list').eq(_this.index());
		_this.addClass('desk-nav-list__item_active').siblings('.js-desk-tab').removeClass('desk-nav-list__item_active');
		currentTab.siblings('.desk-list').hide();
		currentTab.fadeIn(350).css({
			display: 'flex'
		});
	})
});
