const $mainSlider = $('#js-special-slider');
const $mainWrapper = $('.special-section__wrapper');
let imagesSliderApi = null;
let textSliderApi = null;

/** Начальная конфигурация слайдера*/
const baseConfig = {
  loop: true,
  speed: 1000,
  observer: true,
  observeSlideChildren: true,
  observeParents: true,
  spaceBetween: 10,
  noSwipingClass: 'swiper-slide',
}

export function specialSlidersSlideByIndex(index) {
  const apis = [imagesSliderApi, textSliderApi];

  $.each(apis, (inx, el) => el.slideToLoop(index));
}

/** Определение направление движения слайдера*/
export function specialSlidersSlideByDir(direction) {
  const apis = [imagesSliderApi, textSliderApi];

  if (direction === 'next') {
    $.each(apis, (inx, el) => el.slideNext());
  } else {
    $.each(apis, (inx, el) => el.slidePrev());
  }
}

export function specialSliderCreate() {
  imagesSliderCreate();
  textSliderCreate();
}

/** Положение движение слайдера*/
export function specialSliderInit() {
  const $specialImagesSlider = $('.js-specialImagesSlider');
  const $specialTextSlider = $('.js-specialTextSlider');

  imagesSliderApi = new Swiper($('.swiper-container', $specialImagesSlider), {
    ...baseConfig,
    parallax: true,
    spaceBetween: 0,
  });

  textSliderApi = new Swiper($('.swiper-container', $specialTextSlider), {
    ...baseConfig,
    direction: 'vertical',
  });
}

/** Создание слайдера изображений*/
function imagesSliderCreate(){
  const imagesSlider = `<div class="special-images-slider js-specialImagesSlider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
      </div>
    </div>
  </div>`;

  $mainWrapper.append(imagesSlider);

  const $specialImagesSlider = $('.js-specialImagesSlider');

  $('.special-section__content-wrapper', $mainSlider).each((index, element) => {
    const image = $(element).find('.special-section__image').clone();

    image.attr('data-swiper-parallax', '50%');

    $('.swiper-wrapper', $specialImagesSlider)
      .append('<div class="swiper-slide"></div>');

    $('.swiper-slide', $specialImagesSlider)
      .eq(index).append(image);
  });
}

/** Создание слайдера текстового сопровождения*/
function textSliderCreate(){
  const textSlider = `<div class="special-text-slider js-specialTextSlider">
    <div class="swiper-container">
      <div class="swiper-wrapper">
      </div>
    </div>
  </div>`;

  $mainWrapper.append(textSlider);

  const $specialTextSlider = $('.js-specialTextSlider');

  $('.special-section__content-wrapper', $mainSlider).each((index, element) => {
    const text = $(element).find('.special-section__content').clone();
    const url = $(element).attr('href');

    $('.swiper-wrapper', $specialTextSlider)
      .append(`<div class="swiper-slide">
        <a href="${url}"></a>
      </div>`);

    $('.swiper-slide > a', $specialTextSlider)
      .eq(index).append(text);
  });
}
