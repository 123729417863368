import 'slick-carousel';
import { specialSliderCreate, specialSliderInit, specialSlidersSlideByIndex, specialSlidersSlideByDir } from './specialSlider';

/** Тех характеристики слайдеров и их адаптивность отображения*/
$(function() {

  let isAnimated = true;
  let x = 0;
  let y = 0;
  let clicked = false;
  let timeId = null;
  const $cursor = $('.js-specialSliderCursor');

  specialSliderCreate();

  setTimeout(() => {
    specialSliderInit();
  });

  const defaultOptions = {
    infinite: true,
    draggable: true,
    focusOnSelect: false,
    accessibility: false,
    mobileFirst: false,
    speed: 1000,
  }

  const defaultSpecialOptions = {
      ...defaultOptions,
      dots: true,
      arrows: false,
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 6000,
      dotsClass: 'special-section__dots',
      responsive: [
        {
          breakpoint: 6000,
          settings: {
            fade: true,
            autoplay: true,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            fade: false,
            autoplay: false,
          },
        },
      ]
  }

  const specialNavOptions = {
    ...defaultOptions,
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
  }

    $('#js-special-slider').slick(defaultSpecialOptions);
    $('#js-special-nav-slider').slick(specialNavOptions);


/** Взаимод-е со слайдерами*/
    $('#js-special-slider').on('swipe', function(event, slick, direction){
      const currentSlide = slick.currentSlide;
      $('#js-special-nav-slider').slick('slickGoTo', currentSlide);
    });

    $('#js-special-nav-slider').on('swipe', function(event, slick, direction){
      const currentSlide = slick.currentSlide;
      $('#js-special-slider').slick('slickGoTo', currentSlide);

      if (direction === 'right') {
        specialSlidersSlideByDir('prev');
      } else {
        specialSlidersSlideByDir('next');
      }
    });

    $('#js-special-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      const isAutoplay = slick.slickGetOption('autoplay');

      if (isAutoplay) {
        specialSlidersSlideByDir('next');
        $('#js-special-nav-slider').slick('slickGoTo', nextSlide);
      }
    });

    $('.special-nav-list').on('click', 'div.slick-slide:not(.slick-current)', function () {
      const _this = $(this);
      const selectedIndex = _this.data('slick-index');

      if (!clicked) {
        clicked = true;

        _this
          .addClass('slick-current')
          .siblings('.slick-slide')
          .removeClass('slick-current');

        slickPause();
        specialSlidersSlideByIndex(selectedIndex);

        $('#js-special-nav-slider').slick('slickGoTo', selectedIndex);
        $('#js-special-slider').slick('slickGoTo', selectedIndex);

        timeId = setTimeout(() => {
          clicked = false;
        }, 1000);
      }
    })

  /** Появление напрвления иконки курсора при навед-е на слайдер*/
    $('.js-specialSliderNavCursor')
      .on('mousemove', (e) => {
        const $sliderNav = $('.js-specialSliderNavCursor');
        const sliderNavOffset = $sliderNav.offset();
        const offsetLeft = e.clientX - $sliderNav.offset().left;
        const sliderHalfWidth = $sliderNav.width() / 2;

        x = e.pageX - sliderNavOffset.left - 32;
        y = e.pageY - sliderNavOffset.top - 32;

        if (offsetLeft > sliderHalfWidth) {
          $cursor.addClass('is-prev');
        } else {
          $cursor.removeClass('is-prev');
        }
        move();
      })
      .on('click', (e) => {
        const $slider = $('#js-special-slider');
        const $sliderNav = $('#js-special-nav-slider');
        const $sliderNavCursor = $('.js-specialSliderNavCursor');
        const offsetLeft = e.clientX - $sliderNavCursor.offset().left
        const sliderHalfWidth = $sliderNavCursor.width() / 2;

        if (!clicked) {
          clicked = true;

          slickPause();

          if (offsetLeft > sliderHalfWidth) {
            $slider.slick('slickNext');
            $sliderNav.slick('slickNext');
            specialSlidersSlideByDir('next');
          } else {
            $slider.slick('slickPrev');
            $sliderNav.slick('slickPrev');
            specialSlidersSlideByDir('prev');
          }

          timeId = setTimeout(() => {
            clicked = false;
          }, 1000);
        }
      });

    function move() {
      if($cursor.length > 0) {
        $cursor.css('left', `${x}px`);
        $cursor.css('top', `${y}px`);
      }
    }

    /** Остановка движения слайдера*/
    function slickPause() {
      const $slider = $('#js-special-slider');
      const $sliderNav = $('#js-special-nav-slider');

      $slider.slick('slickPause');
      $slider.slick('setOption', 'autoplay', false);
      $sliderNav.slick('slickPause');
      $sliderNav.slick('setOption', 'autoplay', false);
    }
})
