$(function(){

    const isDesktop = $(window).width() > 1024;

    function setMainAnimation() {

        /** Получаем массив ссылок заголовков на каждом слайде */
        const mainLinks = $('.main-slide__title a');

        /** Проходимся циклом по массиву */
        mainLinks.each( index => {

            /** Высчитываем высоту заголовка */
            let mainLink = $(mainLinks[index]),
            blockHeight = mainLink.height(),
            countLines,
            timerId;

            const lineHeight = 42;

            /** Получаем количество строк заголовка */
            countLines= blockHeight / lineHeight;

            /** В этом цикле добавляем в заголовок плашки которые будем анимировать */
            let i = 0;
            while (i < countLines) {
                let lineTemplate = `<span class="animation-line" style="top:${i * lineHeight}px"></span>`;
                mainLink.append(lineTemplate)
                i++;
            }

            mainLink.mouseenter(function() {
                let counter = 0;
                const _this = $(this);

                /** Запускаме таймер который анимирует каждую строку поочередно */

                timerId = setInterval(() => {
                    if (counter <= countLines) {
                        _this.find(`.animation-line`).eq(counter).css({
                            transform: 'translate3d(100%,0,0) translate3d(1px,0,0)'
                        })
                        counter++;
                    }else{
                        /** Обнуляем таймер после анимации всех плашек */
                        clearInterval(timerId);
                        _this.find(`.animation-line`).eq(counter).css({
                            transform: 'translate3d(100%,0,0) translate3d(1px,0,0)'
                        })
                    }
                },150)
            }).mouseleave(function() {
                let counterLeave = countLines;
                const _this = $(this);

                /** При событии mouseleave очищаем таймер
                 * который анимировал каждую строку поочередно
                **/
                clearInterval(timerId);

                /** Запускаем таймер обратной анимации последовательно снизу вверх*/
                let timerLeaveId = setInterval(() => {

                    if (counterLeave >= 0) {
                        _this.find(`.animation-line`).eq(counterLeave).css({
                            transform: 'translate3d(-101%,0,0) translate3d(1px,0,0)'
                        })
                        counterLeave--;
                    }else{

                         /** Очищаем таймер обратной анимации, и
                          * в случае если пользователь навел на заголовок еще раз
                          * Возвращаем в исходное положение текущую анимированную плашку*/

                        clearInterval(timerLeaveId);
                        _this.find(`.animation-line`).eq(counterLeave).css({
                            transform: 'translate3d(-101%,0,0) translate3d(1px,0,0)'
                        })
                    }
                },150)
            })
        })
    }

    /** Set Timeout нужен для того чтобы слайдер инициализировался  */
    setTimeout(() => {
        if(isDesktop){
            setMainAnimation();
        }
    },2100)
})
