const $map = $('#location-map');
const $locationAddressInput = $('.js-locationAddressInput');

function mapInit() {
  if ($map.length > 0) {
    const center = $map.data('center').split(', ');
    const coordinates = [Number(center[0]), Number(center[1])];
    const pin = $map.data('pin');
    const geoObjects = [];

    const mapApi = new ymaps.Map('location-map', {
      center: coordinates,
      zoom: 9,
      controls: []
    }, {
      suppressMapOpenBlock: true,
      yandexMapDisablePoiInteractivity: true
    });

    /** Создание маркеров на карте */
    const createPlacemark = (coords) => {
      const mapPlacemark = new ymaps.Placemark(coords, {}, {
        iconLayout: 'default#image',
        iconImageHref: pin,
        iconImageSize: [35, 42],
        iconImageOffset: [-15, -42]
      });

      geoObjects.push(mapPlacemark);
      mapApi.geoObjects.add(mapPlacemark);
    };

    /** Очистка всех маркеров*/
    const clearAllMarkers = () => {
      $.each(geoObjects, (i) => {
        mapApi.geoObjects.remove(geoObjects[i]);
      });
    };

    /** Геолокация пользователя */
    $('.js-navigation').on('click', (e) => {
      e.preventDefault();
      geolocUser()
      $('.js-block-address').addClass('is_active')
    })

    const geolocUser = () => {

      const geoloc = ymaps.geolocation;
      geoloc.get({
        provider: 'yandex',
        mapStateAutoApply: true
      }).then(function (result) {
        result.geoObjects.options.set('preset', pin);

        const geoCoords = [Number(result.geoObjects.position[0]), Number(result.geoObjects.position[1])];
        const geoBounds = result.geoObjects.get(0).properties.get('boundedBy')

        createPlacemark(geoCoords)
        getAddress(geoCoords);

        mapApi.setBounds(geoBounds, {
          checkZoomRange: true
        });
      });
      clearAllMarkers();
    }

    /** Обработчик увеличения/уменьшения карты */
    const ZoomLayout = ymaps.templateLayoutFactory.createClass("<div/>", {
      build: function () {
        /** Вызываем родительский метод build */
        ZoomLayout.superclass.build.call(this);

        /** Привязываем функции-обработчики к контексту и сохраняем ссылки
         * на них, чтобы потом отписаться от событий. */
        this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
        this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

        /** Начинаем слушать клики на кнопках макета. */
        $('#zoom-in_detail').bind('click', this.zoomInCallback);
        $('#zoom-out_detail').bind('click', this.zoomOutCallback);
      },

      clear: function () {
        /** Снимаем обработчики кликов. */
        $('#zoom-in_detail').unbind('click', this.zoomInCallback);
        $('#zoom-out_detail').unbind('click', this.zoomOutCallback);

        /** Вызываем родительский метод clear. */
        ZoomLayout.superclass.clear.call(this);
      },

      zoomIn: function (e) {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
        e.preventDefault();
      },

      zoomOut: function (e) {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
        e.preventDefault();
      }
    });
    const zoomControl = new ymaps.control.ZoomControl({options: {layout: ZoomLayout}});
    mapApi.controls.add(zoomControl);

    /** Прямое геокодирование */
    function getAddress(coords) {
      ymaps
        .geocode(coords)
        .then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          const addressText = getTownFromAddress(firstGeoObject.properties.get('metaDataProperty.GeocoderMetaData').Address.Components);

          $('.js-locationAddressInput').val(addressText).attr('data-value', addressText).trigger('focusout');
        });
    };

    /** Получение местоположения карты с ввода адреса */
    function getTownFromAddress(data) {
      let locality = '';
      let province = '';
      let street = '';
      let house = '';

      for (let i in data) {
        if (data[i]?.kind === 'locality') {
          locality = data[i]?.name
        }
        if (data[i]?.kind === 'province') {
          province = data[i]?.name
        }
        if (data[i]?.kind === 'street') {
          street = data[i]?.name
        }
        if (data[i]?.kind === 'house') {
          house = data[i]?.name
        }
      }

      const address = [...new Set([locality, province, street, house])].filter(el => el !== '');

      return address.join(', ');
    }

    mapApi.events.add('click', (e) => {
      const coords = e.get('coords');

      clearAllMarkers();
      getAddress(coords);
      createPlacemark(coords);
    });

    /** Геокодирование по введённому адресу из инпута */
    $(document).on('click', '.js-addressLinkButton', (e) => {
      let address = $(e.currentTarget).data('value');
      $('.js-block-address').addClass('is_active')

      ymaps.geocode(address, {
        results: 1
      }).then(function (res) {
        let firstGeoObject = res.geoObjects.get(0);
        let coords = firstGeoObject.geometry.getCoordinates();
        let bounds = firstGeoObject.properties.get('boundedBy');

        clearAllMarkers()
        createPlacemark(coords)

        mapApi.setBounds(bounds, {
          checkZoomRange: true
        });
      })
    })
  }
};

/** Взаимод-е с картой при вводе/очистке поля адреса */
$('.js-locationAddressInput').on('input', () => {
  if ($('.js-locationAddressInput').val() === '') {
    $('.js-block-address').removeClass('is_active');
  }
})

$(document).on('click', '.js-addressLinkButton', (e) => {
  let address = $(e.currentTarget).data('value');
  $('.js-locationAddressInput').attr('data-value', address).trigger('focusout');
})

ymaps.ready(mapInit);
