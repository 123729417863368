/** Появление телефона при нажатии кнопки Показать телефон*/
const $showPhone = $('.js-showPhone');
const $buttonShowPhone = $('.button-show-phone');

$showPhone.on('click', (e) => {
  if (!$buttonShowPhone.hasClass('button-show-phone_visible')) {
    e.preventDefault();
  }
  $buttonShowPhone
    .removeClass('button_theme_orange')
    .addClass('button_theme_gray button-show-phone_visible');
});
