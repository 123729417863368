window.sidebannerPositionInit = function () {
  const $addeskSideBanner = $('.js-addeskSideBanner');
  const $addeskSideBannerFirst = $('.js-addeskSideBanner[data-banner-number="1"]');
  const $addeskFullBanner = $('.js-addeskFullBanner');

  function fullbannerСrossing() {

    /** Расположение баннеров в завис-ти от высоты страницы */
    if ($addeskFullBanner.length > 0) {
      const sidebarValue = $addeskSideBannerFirst.position().top + $addeskSideBannerFirst.height();
      const fullbarValue = $addeskFullBanner.position().top;
      const fullbarMargin = $addeskFullBanner.css('margin-top').replace('px', '');

      if (sidebarValue > fullbarValue) {
        const paddingTop = (sidebarValue - fullbarValue) + Number(fullbarMargin);

        $addeskFullBanner.css('padding-top', paddingTop);
      } else {
        $addeskFullBanner.css('padding-top', '');
      }
    }
  }

  function sidebannerPosition() {

    /** Расположение баннеров в завис-ти от индекса */
    if ($addeskSideBanner.length > 0) {
      $addeskSideBanner.each((index, el) => {
        const prev = $(el).prev();
        if (prev.length > 0) {
          const top = prev.position().top;
          $(el).css('top', top);
        }
      });
    }
    ;
  };
  fullbannerСrossing();
  sidebannerPosition();
}

sidebannerPositionInit();

$(document).ready(function () {
  sidebannerPositionInit();
})

$(window).on('load resize', () => {
  sidebannerPositionInit();
});

$('.js-locationAddressInput').on('focusout', () => {
  /** Очистка адресной строки при отсутствии фокуса */
  if ($('.js-locationAddressInput').val() == "") {
    $('.js-searchFormClear').removeClass('is_visible_clear')
  } else {
    $('.js-searchFormClear').removeClass('is_visible_clear')
    let text = $('.js-locationAddressInput').val().replace(/%/gi, ' ');
    text = text.replace(/%/gi, ' ');
    $('.js-locationAddressInput').val(text)
  }
})

$(document).ready(function () {
  let isDesktop = $(window).width() > 1025;
  console.log('realty')

  if(isDesktop) {
    /** Завис-ть высоты страницы от кол-ва баннеров */
    let isFind = $('.js-container').find('.js-addeskFullBanner')
    let isFind2 = $('.js-container').find('.js-addeskSideBanner')

    if (isFind2 && isFind2.length == 0 && isFind && isFind.length == 0) {
      $('.addesk__section').css({minHeight: "700px"})
    }
    if (isFind && isFind.length == 0 && isFind2 && isFind2.length > 0) {
      $('.addesk__section').css({minHeight: "1100px"})
    }
    if (isFind && isFind.length > 0 && isFind2 && isFind2.length == 1) {
      $('.addesk__section').css({minHeight: "1400px"})
    }
    if (isFind && isFind.length > 0 && isFind2 && isFind2.length > 1) {
      $('.addesk__section').css({minHeight: "2160px"})
    }
  }
})






