let files = []
let clickNum = 0;

$(document).on('change', '.js-uploadInput', function () {
  let $inputFile = $(this);
  files = Array.from($inputFile.prop('files'))
  if (files) {
    uploadhandler(files);
  }
});

/** Создание карточки с изображением*/
let files_id = []
const createCard = (res) => {
  console.log('files_id', files_id)
  let files = Array.from(res.images)
  if ('markup_path' in window) {
    markup_path = window.markup_path;
  } else {
    markup_path = '';
  }
  files.forEach((file, index) => {
    files_id.push(file.id)
    $('.file-upload__list').prepend(`
      <div class="file-upload__card file-upload__img" data-id="${file.id}" data-deg=0>
        <div class="file-card apart-card">
          <a href="${file.imageUrlLarge}" class="lightzoom">
              <img class="file-card__image apart-card__image" src="${file.imageUrl}" alt="${file.name}"/>
          </a>
          <div class="file-card__nav apart-card__nav">
            <div class="file-card__button file-zoom" data-zoom="${file.name}">
              <svg class="icon icon-zoom">
                <use xlink:href="${markup_path}images/sprites.svg#zoom"></use>
              </svg>
            </div>
            <div class="file-card__button file-rotate" data-rotate="${file.name}">
              <svg class="icon icon-rotate">
                <use xlink:href="${markup_path}images/sprites.svg#rotate"></use>
              </svg>
            </div>
            <div class="file-card__button file-del" data-delete="${file.name}">
              <svg class="icon icon-del">
                <use xlink:href="${markup_path}images/sprites.svg#del"></use>
              </svg>
            </div>
          </div>
          <div class="desc-card" data-desc="${file.name}">Сделать главным</div>
        </div>
      </div>
      `)
    $('.file-upload__drop').addClass('file-upload__drop_hidden')
    if ($('.file-upload__wrapper').hasClass('file-upload__wrapper_hidden')) {
      $('.file-upload__wrapper').removeClass('file-upload__wrapper_hidden')
    }

    $('.js-hiddenPhotosInput').val(files_id);

    if (!$('.js-block-photo-video').hasClass('is_active')) {
      $('.js-block-photo-video').addClass('is_active')
    }
  })
}

/** Обработчик нажатия на кнопки внутри карточки*/
const clickCardButton = event => {
  if ($(event.target).parents('.file-upload__img')[0]) {
    const chosenImg = $(event.target).parents('.file-upload__img').data('id')

    if (event.target.dataset.delete) {
      deleteImage(chosenImg)
    }
    if (event.target.dataset.rotate) {
      sendImgToRotate(chosenImg)
    }
    if (event.target.dataset.zoom) {
      zoomImage(chosenImg)
    }
    if (event.target.dataset.desc) {
      makeMainImg(chosenImg)
    }
  }
}

$(document).on('click', '.file-upload__list', clickCardButton)

/** Удаление изображения*/
const deleteImage = (imgId) => {
  console.log('imgId', imgId)
  const delVal = $('.js-hiddenPhotosInput').val().split(',');
  console.log('delVal', delVal)
  const searchVal = delVal.filter(val => val !== imgId);
  files_id = searchVal
  console.log('searchVal', searchVal)
  $('.js-hiddenPhotosInput').val(searchVal.join(','));

  let $imgToFind = $('.file-upload__list').find(`[data-id="${imgId}"]`)
  if ($imgToFind) $imgToFind[0].remove()

  if ($('.file-upload__list').children('.file-upload__img').length === 0) {
    $('.file-upload__wrapper').addClass('file-upload__wrapper_hidden')
    if ($('.file-upload__drop').hasClass('file-upload__drop_hidden')) {
      $('.file-upload__drop').removeClass('file-upload__drop_hidden')
    }

    if ($('.js-videoPreview').hasClass('empty')) {
      $('.js-block-photo-video').removeClass('is_active')
    }

    if ('updateAsideProgress' in window) {
      window.updateAsideProgress();
    }
  }
}

/** Поворот изображения*/
const rotateImage = (res) => {
  const imgId = res.id;
  const imgSrc = res.imageUrl;

  let $imgToFind = $(`[data-id="${imgId}"]`).find('.file-card__image')
  let $hrefToFind = $(`[data-id="${imgId}"]`).find('.lightzoom')
  $imgToFind.attr('src', imgSrc)
  $hrefToFind.attr('href', imgSrc)
}

/** Увеличение изображения*/
const zoomImage = (imgId) => {
  const foundImg = $('.file-upload__list').find(`[data-id="${imgId}"]`)
  const lightImg = foundImg.find('.lightzoom')
  lightImg.lightzoom({
    viewTitle: true,
    isOverlayClickClosing: true,
    isEscClosing: true
  })
  lightImg.trigger('click')
}

/** Добавление изображение в кач-ве главного слайдера*/
const makeMainImg = (res) => {
  const $mainImg = $(`[data-id="${res}"]`).find('.desc-card')
  if ($mainImg) {
    $('.desc-card').text('Сделать главным')
    $mainImg.text('Главное фото')
    $('.js-hiddenMainPhotoInput').val(res)
  }
}

/** Ограничение по загрузке изображений*/
const validateCount = () => {
  const uploaded = $('.file-upload__img').length;
  return uploaded <= 30;
}

/** Загрузка изображения*/
const uploadhandler = (images) => {
  if (!images) return

  const formData = new FormData();
  const ajaxUrl = $('.file-upload__wrapper').data('ajax-images-url');
  const validCount = validateCount();
  const validSize = 31457280; // 30mb
  let totalSize = 0;

  if (validCount) {
    const uploaded = $('.file-upload__img').length;
    const remains = 30 - uploaded;

    $.each(images, (i, file) => {
      totalSize += file.size;

      if (i < remains && totalSize < validSize) {
        formData.append('files[]', file);
      }
    });

    $('.js-uploadInput').val('');

    ajaxRequest(ajaxUrl, formData, createCard);
  }
}

/** Отправка запроса на сервер на поворот*/
const sendImgToRotate = (id) => {
  const ajaxUrl = $('.file-upload__wrapper').data('ajax-images-url');
  if (!id) return
  const fd = new FormData();
  fd.append('rotate', 1);
  fd.append('id', id);

  ajaxRequest(ajaxUrl, fd, rotateImage)
}

/** Шаблон запроса на сервер*/
const ajaxRequest = (url, data, resFunction, methodType = 'POST') => {
  $.ajax({
    url: url,
    type: methodType,
    data: data,
    cache: false,
    dataType: 'json',
    processData: false,
    contentType: false,
    success: function (respond, status, jqXHR) {
      resFunction(respond);

      if ('updateAsideProgress' in window) {
        window.updateAsideProgress();
      }
    }
  })
}

/** Drag and drop uploading*/
function uploadingInit(value) {
  files = []
  clickNum = 0;

  let $dropzone = value
  let imageType = /image.*/;

  if ($dropzone) {
    $dropzone.ondragover = function (e) {
      e.preventDefault();
      this.classList.add('dragover');
    };
    $dropzone.ondragleave = function (e) {
      e.preventDefault();
      this.classList.remove('dragover');
    };
    $dropzone.ondrop = function (e) {
      e.preventDefault();
      this.classList.remove('dragover');
      const files = e.dataTransfer.files;

      let mass = Array.prototype.slice.call(files)
      const newMass = mass.filter(file => file.type.match(imageType))

      uploadhandler(newMass);

    }
  }
}

uploadingInit($('.file-upload__drop')[0]);
uploadingInit($('.file-upload__wrapper')[0]);

window.uploadingReinit = function () {
  // uploadingInit();
  uploadingInit($('.file-upload__drop')[0]);
  uploadingInit($('.file-upload__wrapper')[0]);
}





