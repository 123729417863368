export function priceFormat(labelValue) {
    /** Nine Zeroes for Billions */
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1).replace(/\.?0+$/, "") + " млрд"
    /** Six Zeroes for Millions */
    : (Math.abs(Number(labelValue))).toFixed(1) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1).replace(/\.?0+$/, "") + " млн"
    /** Three Zeroes for Thousands */
    : (Math.abs(Number(labelValue))).toFixed(1) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1).replace(/\.?0+$/, "") + " тыс"

    : (Math.abs(Number(labelValue))).toFixed(1);
}

export function priceFormatAlt(labelValue) {
    /** Nine Zeroes for Billions */
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? Math.abs(Number(labelValue)) / 1.0e+9 + " млрд"
    /** Six Zeroes for Millions */
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? Math.abs(Number(labelValue)) / 1.0e+6 + " млн"
    /** Three Zeroes for Thousands */
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? Math.abs(Number(labelValue)) / 1.0e+3 + "К"

    : Math.abs(Number(labelValue));
}
