$(function() {
  let didScroll;
  let lastScrollTop = 0;
  const delta = 5;
  const navbarHeight = $('.header').outerHeight();
  const newsSection = $('.news-section');
  let isDesktop = $(window).width() > 1024;
  let timeout = '';

  $(window).scroll(function(){
    /** Отображение при скролле */
    didScroll = true;
    const scrolled = $(window).scrollTop();

    if(!isShowedBlock()) {
      $('body').addClass('fixed-header')
      $('.header').addClass('header_fixed header_white');
      $('.menu-list').addClass('menu-list_white');
    } else {
      $('body').removeClass('fixed-header')
      $('.header').removeClass('header_fixed header_white');
      $('.menu-list').removeClass('menu-list_white');
    }

    if (scrolled > navbarHeight) {
      $('.header').addClass('header_animate');
    } else {
      $('.header').removeClass('header_animate');
    }
  });

  setInterval(function() {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    const st = $(window).scrollTop();
    let startPosition = navbarHeight;

    /** Make sure they scroll more than delta */
    if(Math.abs(lastScrollTop - st) <= delta) return;

    /** If they scrolled down and are past the navbar, add class .nav-up.
    * This is necessary so you never see what is "behind" the navbar. */
    if (st > lastScrollTop && st > startPosition){
      /** Scroll Down */
      $('.header').removeClass('nav-down').addClass('nav-up');
    } else {
      /** Scroll Up */
      if(st + $(window).height() < $(document).height()) {
        $('.header').removeClass('nav-up').addClass('nav-down');
      }
    }

    lastScrollTop = st;
  }

  function isShowedBlock() {
    /** Если блок присутствует */
    const heightMainBlock = $('.main-section').height() || $(window).height();
    const scrollTop = $(document).scrollTop();

    if(heightMainBlock - scrollTop <= 0) {
      return false
    }else {
      return true
    }
  }

  $('.js-dropdown-menu').click(function(event){
    /** Открытие/закрытие меню */
    $('.submenu-desk-list').toggleClass('submenu-desk-list_hide');
    event.stopPropagation();
  })

  if(isDesktop) {
    /** Появление/закрытие в десктопной версии */
    $('#js-fixed-userpanel').mouseenter(function(event){
      clearTimeout(timeout);
      $('.user-dropdown-list').removeClass('user-dropdown-list_hide');
    }).mouseleave(function() {
      timeout = setTimeout(() => {
        $('.user-dropdown-list').addClass('user-dropdown-list_hide');
      }, 100);
    })

    $('.user-dropdown-list').mouseenter(function() {
      $(this).removeClass('user-dropdown-list_hide')
      clearTimeout(timeout);
    }).mouseleave(function() {
      $(this).addClass('user-dropdown-list_hide');
    })
  }else{
    $('#js-fixed-userpanel').click(function(event){
      event.stopPropagation();
      $('.user-dropdown-list').removeClass('user-dropdown-list_hide');
    })
  }
})
