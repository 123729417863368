/** Константы выводимых ошибок полей*/
$.extend($.validator.messages, {
  required: 'Поле обязательно к заполнению',
  remote: 'Пожалуйста, введите правильное значение',
  email: 'Указан неверный формат e-mail адреса',
  url: 'Пожалуйста, введите корректный URL',
  date: 'Пожалуйста, введите корректную дату',
  dateISO: 'Пожалуйста, введите корректную дату в формате ISO',
  number: 'Пожалуйста, введите число',
  digits: 'Пожалуйста, вводите только цифры',
  creditcard: 'Пожалуйста, введите правильный номер кредитной карты',
  equalTo: 'Пожалуйста, введите такое же значение ещё раз',
  extension: 'Пожалуйста, выберите файл с правильным расширением',
  maxlength: $.validator.format('Максимальная длина описания - {0} символов'),
  minlength: $.validator.format('Пожалуйста, введите не меньше {0} символов'),
  rangelength: $.validator.format('Пожалуйста, введите значение длиной от {0} до {1} символов'),
  range: $.validator.format('Пожалуйста, введите число от {0} до {1}'),
  max: $.validator.format('Максимальное число - {0}'),
  min: $.validator.format('Пожалуйста, введите число, большее или равное {0}'),
});

/** Отображение ошибки валидируемого поля*/
window.validateInit = function () {
  $('.js-validate').each((index, form) => {
    $(form).validate({
      ignore: ':hidden',
      errorElement: 'span',
      errorClass: 'form-error-text',
      highlight: function (element) {
        setTimeout(() => {
          if (!$(element).attr('disabled')) {
            const parent = $(element).parent();
            parent.addClass('is-error');

            parent.after(parent.find('.form-error-text'));
          }
        });
      },
      unhighlight: function (element) {
        setTimeout(() => {
          if (!$(element).attr('disabled')) {
            if ($(element).val() != '') {
              $(element).parent().addClass('is-success');
            }
            $(element).parent().removeClass('is-error');
          }
        });
      }
    });
  });
}

validateInit();

$(document).on('change', '.js-validate .js-select2Single', function () {
  $(this).parent().removeClass('is-error');
});

/** Валидация полей страницы добавления объявления */

$.validator.addMethod('phonecustom', function (value, element) {
  if (value == '+7 (___) ___-__-__') return true;
  return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/);
}, 'Указан неверный формат телефона');

$.validator.addClassRules('js-phone', {
  phonecustom: true
});

$.validator.addMethod('squarenozero', function (value, element) {

  if (Number(value) > 0) return true
}, 'Площадь должна быть больше 0');

$.validator.addMethod('floornozero', function (value, element) {
  return Number(value) !== 0;
}, 'Этажей должно быть больше 0');

$.validator.addClassRules('js-floorNoZero', {
  floornozero: true
});

$.validator.addMethod("Kitchen",
  function (value, element, param) {

    return value >= Number($('.kitchenSquare').val());
  }, 'Площадь кухни должна быть меньше или равна общей площади');

$.validator.addMethod("lessThan15",
  function (value, element, param) {
    return value.trim().length >= 15;
  }, 'Описание должно содержать не менее 15 символов');

$.validator.addMethod("lessKitchen",
  function (value, element, param) {
    $('.fullSquare').trigger('focusout')
  }, '');

$.validator.addMethod("floorMore",
  function (value, element, param) {
    $('.chosenFloor').trigger('focusout')
    return true;
  }, '');

$.validator.addMethod("priceDeal", function (value, element, param) {
  return value !== '';
}, 'Поле обязательно к заполнению')

$.validator.addMethod("floorLess",
  function (value, element, param) {
    if ($('.floors').val() !== '') {
      return value <= Number($('.floors').val());
    } else {
      return true
    }
  }, 'Этаж не может быть больше, чем этажей в доме.');

$.validator.addClassRules({
  chosenFloor: {
    required: true,
    digits: true,
    floorLess: 'floors',
    floornozero: true
  },
  floors: {
    required: true,
    digits: true,

  },
  'js-descriptionField': {
    required: true,
    lessThan15: true,
  },
  fullSquare: {
    required: true,
    digits: true,
    Kitchen: true,
  },
  kitchenSquare: {
    squarenozero: true,
  },
  priceDeal: {
    required: true,
  },
  'js-squareNoZero': {
    squarenozero: true
  }
});

$('.kitchenSquare').on('focusout', () => {
  $('.fullSquare').trigger('focusout')
})

$('.floors').on('focusout', () => {
  $('.chosenFloor').trigger('focusout')
})

/** Ограничени на ввод*/
$(document).on('keypress', '.name-field', function () {
  var that = this;
  setTimeout(function () {
    var res = /[^а-яА-Я-]/g.exec(that.value);
    that.value = that.value.replace(res, '');
  }, 0);
});

$(document).on('keypress', '.surname-field', function () {
  var that = this;
  setTimeout(function () {
    var res = /[^а-яА-Я-]/g.exec(that.value);
    that.value = that.value.replace(res, '');
  }, 0);
});

$(document).on('keypress', '.address-input', function () {
  var that = this;
  setTimeout(function () {
    var res = /[^а-яА-Яa-zA-Z0-9-, '']+$/g.exec(that.value);
    that.value = that.value.replace(res, '');
  }, 0);
});

function maskPhoneInit() {
  // $(".phone-mask").mask("+7 ?(999) 999-99-99", {autoclear: false});
  let maskPlaceholder = '+7 (999) 999-99-99';

  $('.phone-mask').each((index, element) => {

    var _this = $(element);
    if(_this.data('mask')){
      maskPlaceholder = _this.data('mask');
    }

    _this.inputmask({'mask': maskPlaceholder});
  });
}

maskPhoneInit();

window.validateReinit = function () {
  validateInit();
};

window.maskPhoneReinit = function () {
  maskPhoneInit();
}

$('.js-inputEmail').on('input', () => {
    if ($('.js-inputEmail').val() !== "" && $('.js-inputPass').val() !== "") {
      $('.js-enterButton').removeAttr('disabled')
    } else {
      $('.js-enterButton').prop("disabled", true)
    }
  }
)

$('.js-inputPass').on('input', () => {
    if ($('.js-inputEmail').val() !== "" && $('.js-inputPass').val() !== "") {
      $('.js-enterButton').removeAttr('disabled')
    } else {
      $('.js-enterButton').prop("disabled", true)
    }
  }
)

$('.confirm-input').on('input', () => {
    let filled = true;
    $(".confirm-input").each(function () {
      if ($(this).val() == '') filled = false;
    });
    filled
      ? $('.js-confirmAd').removeAttr('disabled')
      : $('.js-confirmAd').prop("disabled", true)
  }
)

$('.js-descriptionField').on('input', (e) => {
  if (e.currentTarget.value[0] === ' ') {
    e.currentTarget.value = '';
  }
  let text = $('.js-descriptionField').val().trim()
  if (text.length >= 15) {
    $('.js-block-description').addClass('is_active')
  } else {
    $('.js-block-description').removeClass('is_active')
  }
})

/** запись знач-я маски телефона в value */
$('.js-mask').on('input', () => {
  $('.js-mask').attr('value', $('.js-mask').val())
})

/** разрешение на нажатие кнопки размещ-я обявл-я */
function enablePlaceAd(isEnable) {
  if(isEnable) {
    $('.js-placeAd').removeClass('disable-button-click')
  } else {
    $('.js-placeAd').addClass('disable-button-click')
  }
}









