import svg4everybody from 'svg4everybody'
// import $ from 'jquery'
import './vendor/jquery-3.5.1.min.js'
import 'jquery-gray';
import 'magnific-popup';
import './vendor/swiper.min';
import './vendor/select2.min';
import './vendor/fotorama';
import './vendor/lightzoom';

import './vendor/jquery-validation';
// import './vendor/jquery.maskedinput.min';
import './vendor/jquery.inputmask.bundle.min';

svg4everybody();

window.$ = $;
window.jQuery = $;
