import { mainSlidersSlideByDir } from './mainSlider';

document.addEventListener("DOMContentLoaded", function () {

  const $cursor = $('.js-mainSliderCursor');
  let x = 0;
  let y = 0;

  function move() {
    /** Проверка на наличие курсора */
    if($cursor.length > 0) {
      $cursor.css('left', `${x}px`);
      $cursor.css('top', `${y}px`);
    }
  }

  $('#js-main-slider')
    .on('mousemove', (e) => {
      /** Определение местоположения стрелки */
      const helper = sliderHelper(e);
      const { $slider, sliderHalfWidth, offsetLeft, startCenter, endCenter } = helper;
      const isTitle = $(e.target).parents('.main-slide__title').length > 0;
      const isPagination = $(e.target).parents('.main-slide__dots').length > 0;

      x = e.pageX - 32;
      y = e.pageY - 32;

      if (isTitle ||
        isPagination ||
        offsetLeft < 220 ||
        offsetLeft > startCenter && offsetLeft < endCenter) {
        $cursor.removeClass('is-visible');
        $slider.removeClass('is-cursor-visible');
      } else if (offsetLeft > 220) {
        $slider.addClass('is-cursor-visible');
        $cursor.addClass('is-visible');
      }

      /** Проверки на поворот стрелки (влево/вправо) */
      if (offsetLeft - 100 > sliderHalfWidth) {
        $cursor.addClass('is-prev');
      } else {
        $cursor.removeClass('is-prev');
      }
      move();
    })
    .on('mouseleave', () => {
      $cursor.removeClass('is-visible');
      $('#js-main-slider').removeClass('is-cursor-visible');
    })
    .on('click', (e) => {
      /** Обработчик нажатия направления движения слайдера */
      const helper = sliderHelper(e);
      const { $slider, offsetLeft, startCenter, endCenter } = helper;
      const link = $slider.find('.slick-active .main-slide__title a');
      const isPagination = $(e.target).parents('.main-slide__dots').length > 0;

      if (isPagination || $(window).width() <= 1024) return;

      $slider.addClass('slickPause');
      $slider.slick('slickPause');
      $slider.slick('setOption', 'autoplay', false);

      if (offsetLeft > 200) {
        if (offsetLeft > startCenter && offsetLeft < endCenter) {
          window.location.href = link.attr('href');
        }

        if (offsetLeft < startCenter) {
          $slider.slick('slickPrev');
          mainSlidersSlideByDir('prev');
        }

        if (offsetLeft > endCenter) {
          $slider.slick('slickNext');
          mainSlidersSlideByDir('next');
        }
      }
    })
    .on('beforeChange', function(event, slick, currentSlide, nextSlide){
      const isAutoplay = slick.slickGetOption('autoplay');
      /** Автопроигрывание слайдов в завис-ти от напрвления */
      if (isAutoplay) {
        mainSlidersSlideByDir('next');
      }
    })

  function sliderHelper(e){
    const $slider = $('#js-main-slider');
    const offsetLeft = e.clientX - $slider.offset().left
    const sliderHalfWidth = $slider.width() / 2;
    const startCenter = (sliderHalfWidth + 110) - 60;
    const endCenter = (sliderHalfWidth + 110) + 60;

    return {
      $slider,
      offsetLeft,
      sliderHalfWidth,
      startCenter,
      endCenter,
    }
  }
});
