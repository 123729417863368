/** Появление/Отображение кнопки Найти*/
$('.js-find').on('change keyup', () => {
  const isMobile = $(window).width() < 768
  const el = $(this);
  const findButton = $(this).siblings('button');
  const quantityLetters = el.val().trim().length;

  (quantityLetters && !isMobile)
    ? findButton.fadeIn(500)
    : findButton.fadeOut(300);
});

$('#js-fixed-search').on('click', () => {
  $('body').addClass('is-search-open');
  $('#js-header-nav').hide();
  $('.header__actions').hide();
  $('#js-search-line').fadeIn(300);
});

/** Исчезновение кнопки Найти*/
$('.js-topSearchClose').on('click', () => {
  const isDesktop = $(window).width() > 1024;

  if (isDesktop) {
    $('#js-header-nav').fadeIn(300);
  }

  $('body').removeClass('is-search-open');
  $('#js-search-line').fadeOut(300);
  $('.header__actions').show();
});
