$(document).ready(function () {
  let newsState = window.history.state && window.history.state.newsItemsHtml || ''
  let activeTab = window.history.state && window.history.state.activeNewsTab ? window.history.state.activeNewsTab : 0;

  if (!!activeTab || activeTab === 0 && !!newsState) {
    /** Выбор актуального контента */
    setTimeout(() => {
      if (activeTab !== 0) {
        $('.news-section__content').html(newsState);
        $('.news-nav-list__item').eq(activeTab).click();
      } else {
        $('.news-section__content').html(newsState);
      }

      window.history.replaceState(null, '', '');
    }, 0)
  }

  $('.news-nav-list').on('click', 'li:not(.news-nav-list__item_active)', function () {
    /** Стилизация элемента списка */
    const _this = $(this);
    const activeNewsTab = _this.index();
    const currentTab = $('.news-section__content').find('.news-list').eq(_this.index());
    _this.addClass('news-nav-list__item_active').siblings('.js-news-tab').removeClass('news-nav-list__item_active');
    currentTab.siblings('.news-list').hide();
    currentTab.fadeIn(350).css({
      display: 'flex',
      opacity: 1
    });
    const newsItemsHtml = $('.news-section__content').html();
    window.history.pushState({newsItemsHtml, activeNewsTab}, '', '');
  })

  /** Запрос на сервер при клике на карточку по заданному индексу */
  $(document).on('click', '.js-news-ajax', function (e) {
    e.preventDefault();
    const activeNewsTab = $('.news-nav-list__item_active').index();
    var page = $(this).data('page'),
      url = $(this).data('url'),
      section = $(this).parents('.news-list').data('section'),
      $link = $(this);
    $.get(url + '?SECTION_ID=' + section + '&PAGEN_1=' + page, function (data) {
      $link.replaceWith(data);
    }).then(function () {
      const newsItemsHtml = $('.news-section__content').html();
      window.history.pushState({newsItemsHtml, activeNewsTab}, '', '');

      grayscaleCardsInit();
    })
  });


  function grayscaleCardsInit() {

    /** Добавление фильтра карточке */
    $('.news-card__image, .news-world-card__image').each((index, el) => {
      if ($(el).find('picture').length < 2) {
        const html = $(el).html();

        $(el).append(html);

        $(el).find('picture:eq(1) img').addClass('grayscale');
      }
    });
  }

  grayscaleCardsInit();
});
